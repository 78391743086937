import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../../../app/config";
import moment from "moment";
import { tokenFormat } from "../../../../app/utilities/format";
import ReactPaginate from "react-paginate";

const CurrentBids = ({ currentBids }) => {
  const [currentItems, setCurrentItems] = useState([]);
  //const [currentBids, setCurrentBids] = useState([]);

  // useEffect(() => {
  //   if (!nameBids) return;console.log('currentBids render')

  //   let rows = nameBids.rows;
  //   rows = rows.filter((row) => row.high_bid >= 0);
  //   rows.sort((rowA, rowB) => rowB.high_bid - rowA.high_bid);

  //   setCurrentBids(rows);
  // }, [nameBids]);


  const itemsPerPage = 20;
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(currentBids.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(currentBids.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, currentBids]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % currentBids.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Bidder</th>
            <th scope="col">Amount (WAX)</th>
            <th scope="col">Bid Time</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((currentBid, index) => (
            <tr key={index}>
              <td>
                <Link to={`/account/${currentBid.newname}`}>
                  {currentBid.newname}
                </Link>
              </td>
              <td>
                <Link to={`/account/${currentBid.high_bidder}`}>
                  {currentBid.high_bidder}
                </Link>
              </td>
              <td>
                {" "}
                {tokenFormat(
                  currentBid.high_bid / Math.pow(10, config.token_precision)
                )}
              </td>
              <td> {moment(currentBid.last_bid_time).format("MMM DD YYYY, HH:mm:ss")}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        className={"pagination"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
      /> 
    </>
  );
};

export default CurrentBids;
