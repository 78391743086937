import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import BlockchainApiManager from "../../../../app/services/blockchainApiManager";
import { bytesFormat } from "../../../../app/utilities/format";

const TopRam = () => {
  const {
    isLoading,
    error,
    data: topRamHolders,
  } = useQuery(
    [`topRamHolders`],
    () => BlockchainApiManager.getTopRamHolders(),
    {}
  );

  if (error) {
    return (
      <>
        <p>Error fetching</p>
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <p>Loading...</p>
      </>
    );
  }

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Rank</th>
            <th scope="col">Account</th>
            <th scope="col">Total RAM</th>
          </tr>
        </thead>
        <tbody>
          {topRamHolders.map((topRamHolder, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <Link to={`/account/${topRamHolder[0]}`}>
                  {topRamHolder[0]}
                </Link>
              </td>
              <td> {bytesFormat(topRamHolder[1],2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default TopRam;
