import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import PageTransition from "../../app/animations/page-transition";
import BlockchainApiManager from "../../app/services/blockchainApiManager";
import {
  bytesFormat,
  currencyFormat,
  microsecondsFormat,
  tokenFormat,
} from "../../app/utilities/format";
import NftAssets from "./components/nft-assets";
import Transactions from "./components/transactions";
import { calculateBreakDown } from "../../app/utilities/account";
import Helmet from "react-helmet";
import { createBrowserHistory } from "history";
import ResourcePercentage from "../wallet/components/resource-percentage";

//const NftAssets = React.lazy(() => import("./components/nft-assets"));

const Account = () => {
  let params = useParams();
  const location = useLocation();
  const history = createBrowserHistory();
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    const hash = location.hash.replace("#", "") || "transactions";
    setActiveTab(hash);
  }, [location]);

  const {
    isLoading,
    error,
    data: account,
  } = useQuery(
    [`account`, params.account],
    () => BlockchainApiManager.getAccount(params.account),
    {}
  );

  const { data: delband } = useQuery(
    [`delband`, params.account],
    () =>
      BlockchainApiManager.getTableRows({
        code: "eosio",
        scope: params.account,
        table: "delband",
        limit: -1,
      }),
    {}
  );

  const { data: userres } = useQuery(
    [`userres`, params.account],
    () =>
      BlockchainApiManager.getTableRows({
        code: "eosio",
        scope: params.account,
        table: "userres",
        limit: -1,
      }),
    {}
  );

  const breakdown = calculateBreakDown({ account, delband, userres });

  if (error) {
    return (
      <>
        <div className="card card-body p-3">
          <h5 className="card-title">Account Not Found</h5>
          <p>Cannot find Account {params.account}</p>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{params.account} | Account - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <h3>{account ? account.account_name : <Skeleton />}</h3>
        <p className="text-muted">
          created {account ? moment(account.created).fromNow() : <Skeleton />}
        </p>

        <div className="container">
          <div className="row p-2">
            <div className="col-md-6">
              <span className="d-block fw-bold  fs-4">
                {account ? (
                  `${tokenFormat(
                    breakdown.totalBalance
                  )} WAX  ($${currencyFormat(breakdown.totalBalance * 0.292)})`
                ) : (
                  <Skeleton />
                )}
              </span>
              <span className="d-block text-muted">Total WAX balance</span>
            </div>
            <div className="col-md-6">
              <span className="d-block">
                {account ? (
                  `${tokenFormat(breakdown.liquidBalance)} WAX`
                ) : (
                  <Skeleton />
                )}
              </span>
              <span className="d-block text-muted">Available WAX balance</span>
            </div>
          </div>
          <div className="row p-2 border">
            <div className="col">
              <span className="d-block fw-bold">eosio</span>
              <span className="d-block text-muted">Token</span>
            </div>
            <div className="col">
              <span className="d-block fw-bold">
                {account ? (
                  bytesFormat(account.ram_quota - account.ram_usage, 2)
                ) : (
                  <Skeleton />
                )}
              </span>
              <span className="d-block text-muted">RAM</span>
            </div>
            <div className="col">
              <span className="d-block fw-bold">
                {" "}
                {account ? (
                  microsecondsFormat(account.cpu_limit.available, 2)
                ) : (
                  <Skeleton />
                )}
              </span>
              <span className="d-block text-muted">CPU</span>
            </div>
            <div className="col">
              <span className="d-block fw-bold">
                {account ? (
                  bytesFormat(account.net_limit.available, 2)
                ) : (
                  <Skeleton />
                )}
              </span>
              <span className="d-block text-muted">NET</span>
            </div>
            <div className="col">
              <span className="d-block fw-bold">
                {account ? (
                  `${tokenFormat(breakdown.unstaking, 4)} WAX`
                ) : (
                  <Skeleton />
                )}
              </span>
              <span className="d-block text-muted">Refunding</span>
            </div>
            <div className="col">
              <span className="d-block fw-bold">
                {account ? (
                  `${breakdown.stakedByOthers.toLocaleString()} WAX`
                ) : (
                  <Skeleton />
                )}
              </span>
              <span className="d-block text-muted">Staked by others</span>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row  p-2">
            <div className="col-md-8">
              {account ? (
                <ResourcePercentage account={account} />
              ) : (
                <Skeleton />
              )}
            </div>
            <div className="col-md-4">
              <ul>
                <li>
                  RAM{" "}
                  {account ? (
                    <>
                      ({bytesFormat(account.ram_usage, 2)} /{" "}
                      {bytesFormat(account.ram_quota, 2)})
                    </>
                  ) : (
                    <Skeleton />
                  )}
                </li>
                <li>
                  CPU
                  {account ? (
                    <>
                      ({microsecondsFormat(account.cpu_limit.used, 2)} /{" "}
                      {microsecondsFormat(account.cpu_limit.max, 2)})
                    </>
                  ) : (
                    <Skeleton />
                  )}
                </li>
                <li>
                  NET
                  {account ? (
                    <>
                      ({bytesFormat(account.net_limit.used, 2)} /{" "}
                      {bytesFormat(account.net_limit.max, 2)})
                    </>
                  ) : (
                    <Skeleton />
                  )}
                </li>
              </ul>
              <Link className="btn btn-sm btn-primary" to={`/wallet/resources`}>
                Stake Resources
              </Link>
            </div>
          </div>
        </div>

        <div className="card card-body p-3 mt-3">
          <ul className="nav nav-tabs mt-2" id="accountTab">
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "transactions" ? "active" : ""
                }`}
                id="transactions-tab"
                data-bs-toggle="tab"
                data-bs-target="#transactions"
                type="button"
                role="tab"
                aria-controls="transactions"
                aria-selected="true"
                onClick={() => {
                  history.push({ hash: "transactions" });
                  setActiveTab("transactions");
                }}
              >
                Transactions
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "nft-assets" ? "active" : ""
                }`}
                id="nft-assets-tab"
                data-bs-toggle="tab"
                data-bs-target="#nft-assets"
                type="button"
                role="tab"
                aria-controls="nft-assets"
                aria-selected="true"
                onClick={() => {
                  history.push({ hash: "nft-assets" });
                  setActiveTab("nft-assets");
                }}
              >
                NFT Assets
              </button>
            </li>
          </ul>
          <div className="tab-content" id="accountTabContent">
            <div
              className={`tab-pane fade show ${
                activeTab === "transactions" ? "active" : null
              }`}
              id="transactions"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              {activeTab === "transactions" ? <Transactions /> : null}
            </div>
            <div
              className={`tab-pane fade show ${
                activeTab === "nft-assets" ? "active" : null
              }`}
              id="nft-assets"
              role="tabpanel"
              aria-labelledby="nft-assets-tab"
            >
              {activeTab === "nft-assets" ? <NftAssets /> : null}
            </div>
          </div>
        </div>
      </PageTransition>
    </>
  );
};

export default Account;
