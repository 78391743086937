import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import PageTransition from "../../../../app/animations/page-transition";
import config from "../../../../app/config";
import ApiManager from "../../../../app/services/apiManager";
//import proxiesData from "../../../../dummy-data/proxies.json";

const ProxiesTab = React.memo(() => {
  //const [proxies, setProxies] = useState(proxiesData);
  const [currentItems, setCurrentItems] = useState([]);

  const {
    //  isLoading,
    // error,
    data: proxies,
    refetch,
  } = useQuery([`proxies`], () => ApiManager.getProxies(), {enabled: false});

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      refetch();
    }, 500);
    
    return () => clearTimeout(timeoutId);
  }, []);

  const itemsPerPage = 100;
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    if (!proxies) return;
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(proxies.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(proxies.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, proxies]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % proxies.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
    <PageTransition>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Rank</th>
            <th scope="col">Account</th>
            <th scope="col">Total WAX</th>
            <th scope="col">Proxied Accounts</th>
            <th scope="col">Voting For</th>
          </tr>
        </thead>
        <tbody>
          {!proxies &&
            Array(5)
              .fill(0)
              .map((item, index) => (
                <tr key={index}>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              ))}
          {currentItems.map((proxy, index) => (
              <tr key={index}>
                <td>{proxy.rank}</td>
                <td>
                  <img
                    src={`${
                      proxy.logo_256
                        ? `${config.imagekit.default_url_endpoint}/${proxy.logo_256}`
                        : "/no-image.png"
                    }`}
                    alt=""
                    width={30}
                    height={30}
                  />{" "}
                  <Link to={`/account/${proxy.account}`}>{proxy.account}</Link>
                </td>
                <td>{proxy.proxied_eos.toLocaleString()}</td>
                <td>{proxy.vote_count.toLocaleString()}</td>
                <td>{proxy.producer_count.toLocaleString()}</td>
              </tr>
            ))}
        </tbody>
      </table>
      <ReactPaginate
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        className={"pagination"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
      />
      </PageTransition>
    </>
  );
});

export default ProxiesTab;
