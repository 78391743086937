import { Link, Outlet, useLocation } from "react-router-dom";
import PageTransition from "../../../app/animations/page-transition";

const Vote = () => {
  const location = useLocation();
  
  return (
    <>
      <PageTransition>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              className={`nav-link ${
                ["/wallet/vote", "/wallet/vote/producers"].includes(
                  location.pathname
                )
                  ? "active"
                  : ""
              }`}
              to="/wallet/vote/producers"
            >
              Producers
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                ["/wallet/vote/proxies"].includes(location.pathname)
                  ? "active"
                  : ""
              }`}
              to="/wallet/vote/proxies"
            >
              Proxies
            </Link>
          </li>
        </ul>
        <Outlet />
      </PageTransition>
    </>
  );
};

export default Vote;
