import { useState } from "react";
import BlockchainApiManager from "../../../../app/services/blockchainApiManager";

const Authorizations = ({ formData, setFormData, actionIndex }) => {
  const [autocompleteAuthorizationActors, setAutocompleteAuthorizationActors] =
    useState([[]]);
  const [authorizationPermissions, setAuthorizationPermissions] = useState([
    [],
  ]);

  const addAuthorization = (actionIndex) => {
    formData.actions[actionIndex].authorization.push({
      actor: "",
      permission: "",
    });
    setFormData({ ...formData });
  };

  const removeAuthorization = (actionIndex, index) => {
    formData.actions[actionIndex].authorization.splice(index, 1);
    setFormData({ ...formData });
  };

  const autocompleteAuthorizationActor = (actionIndex, index) => {
    if (
      !new RegExp("^[a-z1-5.]{1,12}$", "i").test(
        formData.actions[actionIndex].authorization[index].actor
      )
    ) {
      autocompleteAuthorizationActors[actionIndex][index] = [];
      setAutocompleteAuthorizationActors([...autocompleteAuthorizationActors]);
      return;
    }

    var keywords = formData.actions[actionIndex].authorization[index].actor;

    if (keywords === "") {
      keywords = "eosio";
    }

    BlockchainApiManager.getTableByScope({
      code: "eosio",
      table: "userres",
      lower_bound: keywords,
      upper_bound: keywords.padEnd(12, "z"),
      limit: 10,
    }).then((res) => {
      autocompleteAuthorizationActors[actionIndex][index] = [];
      if (res && res.rows) {
        res.rows.forEach((item, key) => {
          autocompleteAuthorizationActors[actionIndex][index].push({
            scope: item.scope,
          });
        });
      }

      setAutocompleteAuthorizationActors([...autocompleteAuthorizationActors]);
    });
  };

  const selectAuthorizationActor = (actionIndex, index, authorizationActor) => {
    if (authorizationActor) {
      formData.actions[actionIndex].authorization[index].actor =
        authorizationActor.scope;
    }

    setFormData({ ...formData });

    authorizationPermissions[actionIndex][index] = [];

    BlockchainApiManager.getAccount(
      formData.actions[actionIndex].authorization[index].actor
    ).then((res) => {
      autocompleteAuthorizationActors[index] = [];
      res.permissions.forEach((permission, key) => {
        authorizationPermissions[actionIndex][index].push(permission);
      });

      setAuthorizationPermissions([...authorizationPermissions]);
    });
  };

  return (
    <>
      <div className="mb-3">
        <label className="form-label">Authorizations</label>
        {formData.actions[actionIndex].authorization.map(
          (authorization, index) => (
            <div className="row mb-2" key={index}>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Actor"
                  value={authorization.actor}
                  onChange={(event) => {
                    formData.actions[actionIndex].authorization[index].actor =
                      event.target.value;
                    setFormData({ ...formData });
                    autocompleteAuthorizationActor(actionIndex, index);
                  }}
                  id={`dropdownAuthorizationActorSearch${actionIndex}_${index}`}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  autoComplete="off"
                />
                <ul
                  className={`dropdown-menu`}
                  aria-labelledby={`dropdownAuthorizationActorSearch${actionIndex}_${index}`}
                >
                  {autocompleteAuthorizationActors[actionIndex] &&
                    autocompleteAuthorizationActors[actionIndex][index] &&
                    autocompleteAuthorizationActors[actionIndex][index].map(
                      (item, key) => (
                        <li
                          key={key}
                          onClick={(e) => {
                            e.preventDefault();
                            selectAuthorizationActor(actionIndex, index, item);
                          }}
                          className="dropdown-item"
                        >
                          <a className="dropdown-item" href="">
                            {item.scope}
                          </a>
                        </li>
                      )
                    )}
                </ul>
              </div>
              <div className="col">
                <select
                  className="form-select"
                  onChange={(event) => {
                    formData.actions[actionIndex].authorization[
                      index
                    ].permission = event.target.value;
                    setFormData({ ...formData });
                  }}
                >
                  <option value="">--Select--</option>
                  {authorizationPermissions[actionIndex] &&
                    authorizationPermissions[actionIndex][index] &&
                    authorizationPermissions[actionIndex][index].map(
                      (permission, key) => (
                        <option key={key} value={permission.perm_name}>
                          {permission.perm_name}
                        </option>
                      )
                    )}
                </select>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => removeAuthorization(actionIndex, index)}
                >
                  Delete
                </button>
              </div>
            </div>
          )
        )}

        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => addAuthorization(actionIndex)}
        >
          Add new
        </button>
      </div>
    </>
  );
};

export default Authorizations;
