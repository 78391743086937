import { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import PageTransition from "../../../../app/animations/page-transition";
import WalletTransactionAlert from "../../../../app/components/wallet-transaction-alert";
import config from "../../../../app/config";
import { WalletContext } from "../../../../app/contexts/wallet-context";
import { tokenFormat } from "../../../../app/utilities/format";

const Stake = () => {
  const wallet = useContext(WalletContext);
  const { user } = wallet;
  const { delband } = wallet.walletAccount;
  const [formData, setFormData] = useState({
    from: "",
    receiver: "",
    stake_net_quantity: 0,
    stake_cpu_quantity: 0,
    transfer: false,
  });

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      from: user ? user.actor : "",
      receiver: user ? user.actor : "",
    }));
  }, [user]);

  const handleClick = async () => {
    var walletTransaction = {};
    walletTransaction.type = "delegatebw";
    // walletTransaction.account = {
    //   account: null,
    //   permission: "active",
    // };
    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio",
      name: "delegatebw",
      data: {
        from: formData.from,
        receiver: formData.receiver,
        stake_cpu_quantity: `${tokenFormat(formData.stake_cpu_quantity)} ${
          config.token_symbol
        }`,
        stake_net_quantity: `${tokenFormat(formData.stake_net_quantity)} ${
          config.token_symbol
        }`,
        transfer: formData.transfer,
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  return (
    <>
    <Helmet>
        <title>Stake | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="container border p-2">
          <form>
            <div className="mb-3">
              <label className="form-label">Receiver of Stake</label>
              <input
                type="text"
                className="form-control dropdown-toggle"
                value={formData.receiver}
                onChange={(event) =>
                  setFormData({ ...formData, receiver: event.target.value })
                }
                id="dropdownMenuStakeReceiver"
                data-bs-toggle="dropdown"
                autoComplete="off"
              />
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuStakeReceiver"
              >
                {delband &&
                  delband.rows.map((row, index) => (
                    <li key={index}>
                      <Link
                        className="dropdown-item"
                        to=""
                        onClick={(event) => {
                          event.preventDefault();
                          setFormData({ ...formData, receiver: row.to });
                        }}
                      >
                        {`${row.to} (CPU: ${row.cpu_weight} , NET: ${row.net_weight})`}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="mb-3">
              <label className="form-label">
                Amount of WAX to stake for CPU
              </label>
              <input
                type="number"
                className="form-control"
                value={formData.stake_cpu_quantity}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    stake_cpu_quantity: event.target.value,
                  })
                }
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Amount of WAX to stake for NET
              </label>
              <input
                type="number"
                className="form-control"
                value={formData.stake_net_quantity}
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    stake_net_quantity: event.target.value,
                  })
                }
              />
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleClick()}
            >
              {formData.stake_cpu_quantity > 0 ||
              formData.stake_net_quantity > 0
                ? `Stake ${formData.stake_cpu_quantity} ${config.token_symbol}
                of CPU and
                ${formData.stake_net_quantity} ${config.token_symbol}
                of NET`
                : `Stake`}
            </button>
          </form>
        </div>
        <WalletTransactionAlert />
      </PageTransition>
    </>
  );
};

export default Stake;
