import { useState } from "react";
import BlockchainApiManager from "../../../../app/services/blockchainApiManager";
import Authorizations from "./authorizations";

const Actions = ({ formData, setFormData }) => {
  const [autocompleteContracts, setAutocompleteContracts] = useState([[]]);
  const [contractAbis, setContractAbis] = useState([]);
  const [autocompleteNames, setAutocompleteNames] = useState([[]]);

  const addAction = () => {
    formData.actions.push({
      contract: "",
      action: "",
      authorization: [{ actor: "", permission: "" }],
      data: {},
    }); //Add new
    setFormData({ ...formData });
  };

  const removeAction = (actionIndex) => {
    formData.actions.splice(actionIndex, 1);
    setFormData({ ...formData });
  };

  const autocompleteContract = (actionIndex) => {
    if (
      !new RegExp("^[a-z1-5.]{1,12}$", "i").test(
        formData.actions[actionIndex].account
      )
    ) {
      autocompleteContracts[actionIndex] = [];
      setAutocompleteContracts({
        ...autocompleteContracts,
        autocompleteContracts,
      });
      return;
    }

    var keywords = formData.actions[actionIndex].account;

    if (keywords === "") {
      keywords = "eosio";
    }

    BlockchainApiManager.getTableByScope({
      code: "eosio",
      table: "userres",
      lower_bound: keywords,
      upper_bound: keywords.padEnd(12, "z"),
      limit: 10,
    }).then((res) => {
      autocompleteContracts[actionIndex] = [];
      res.rows.forEach((item, key) => {
        autocompleteContracts[actionIndex].push({ scope: item.scope });
      });

      setAutocompleteContracts([...autocompleteContracts]);
    });
  };

  const selectContract = (actionIndex, item) => {
    const contract = item.scope;
    if (contract) {
      formData.actions[actionIndex].account = contract;
    }
    setFormData({ ...formData });

    contractAbis[actionIndex] = [];

    autocompleteNames[actionIndex] = autocompleteNames[actionIndex]
      ? autocompleteNames[actionIndex]
      : [];
    autocompleteNames[actionIndex] = [];

    BlockchainApiManager.getAbi(formData.actions[actionIndex].account).then(
      (res) => {
        contractAbis[actionIndex] = res;
        autocompleteNames[actionIndex] = [];

        if (res && res.abi) {
          res.abi.actions.forEach((item, key) => {
            autocompleteNames[actionIndex].push(item.name);
          });
        }

        setContractAbis([...contractAbis]);
        setAutocompleteNames([...autocompleteNames]);
      }
    );
  };

  const selectName = (actionIndex, name) => {
    if (name) {
      formData.actions[actionIndex].name = name;
    }

    if (contractAbis[actionIndex]) {
      contractAbis[actionIndex].abi.structs.forEach((struct, key) => {
        if (struct.name === name) {
          formData.actions[actionIndex].data = {};
          struct.fields.forEach((field, key) => {
            formData.actions[actionIndex].data[field.name] = "";
          });
        }
      });
      setFormData({ ...formData });
    }
  };

  return (
    <>
      <div className="mb-3">
        {formData.actions.length === 0 ? <p>No Actions found</p> : null}

        {formData.actions.map((action, actionIndex) => (
          <div key={actionIndex} className="border p-4 mb-3">
            <div className="row">
              <div className="col-md-10">
                <label className="form-label">
                  Action {actionIndex + 1} - {action.account}::
                  {action.name}
                </label>
              </div>
              <div className="col-md-2">
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => removeAction(actionIndex)}
                >
                  Delete
                </button>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Contract</label>
              <input
                type="text"
                className="form-control"
                value={action.account}
                onChange={(event) => {
                  formData.actions[actionIndex].account = event.target.value;
                  setFormData({ ...formData });
                  autocompleteContract(actionIndex);
                }}
                id={`dropdownContractSearch${actionIndex}`}
                data-bs-toggle="dropdown"
                // data-bs-autoClose="true"
                aria-expanded="false"
                autoComplete="off"
              />
              <ul
                className={`dropdown-menu `}
                aria-labelledby={`dropdownContractSearch${actionIndex}`}
              >
                {autocompleteContracts[actionIndex] &&
                  autocompleteContracts[actionIndex].map((item, key) => (
                    <li
                      key={key}
                      onClick={(e) => {
                        e.preventDefault();
                        selectContract(actionIndex, item);
                      }}
                      className="dropdown-item"
                    >
                      <a className="dropdown-item" href="">
                        {item.scope}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="mb-3">
              <label className="form-label">Action</label>
              <input
                type="text"
                className="form-control"
                value={action.name}
                id={`dropdownNameSearch${actionIndex}`}
                onChange={(event) => {
                  formData.actions[actionIndex].name = event.target.value;
                  setFormData({ ...formData });
                }}
                data-bs-toggle="dropdown"
                // data-bs-autoClose="true"
                aria-expanded="false"
                autoComplete="off"
              />
              <ul
                className={`dropdown-menu`}
                aria-labelledby={`dropdownNameSearch${actionIndex}`}
              >
                {autocompleteNames[actionIndex] &&
                  autocompleteNames[actionIndex].map((name, key) => (
                    <li
                      key={key}
                      onClick={(e) => {
                        e.preventDefault();
                        selectName(actionIndex, name);
                      }}
                      className="dropdown-item"
                    >
                      <a className="dropdown-item" href="">
                        {name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
            <Authorizations
              formData={formData}
              setFormData={setFormData}
              actionIndex={actionIndex}
            />
            <div className="mb-3">
              <label className="form-label">Data</label>

              {Object.keys(action.data).map((key, index) => (
                <div className="mb-3" key={index}>
                  <label className="form-label">{key}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={typeof action.data[key] === 'object'?JSON.stringify(action.data[key]):action.data[key]}
                    onChange={(event) => {
                      formData.actions[actionIndex].data[key] =
                        event.target.value;
                      setFormData({ ...formData });
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={addAction}
        >
          Add new action
        </button>
      </div>
    </>
  );
};

export default Actions;
