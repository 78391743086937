import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useQuery } from "react-query";
import BlockchainApiManager from "../../../app/services/blockchainApiManager";
import { Link } from "react-router-dom";
import RequestedApprovals from "./components/requested-approvals";
import Actions from "./components/actions";
import { WalletContext } from "../../../app/contexts/wallet-context";
import { Api, JsonRpc } from "eosjs";
import { JsSignatureProvider } from "eosjs/dist/eosjs-jssig";
import config from "../../../app/config";
import PageTransition from "../../../app/animations/page-transition";
import Helmet from "react-helmet";

const Msig = () => {
  const wallet = useContext(WalletContext);
  const [formData, setFormData] = useState({
    proposer: "",
    proposal_name: "",
    requested: [{ actor: "", permission: "" }],
    actions: [
      // {
      //   account: "",
      //   name: "",
      //   authorization: [{ actor: "", permission: "" }],
      //   data: {},
      // },
    ],
    settings: {
      enabled: false, //Toggle show hide
      delay_sec: 0,
      expiration: moment().utc().add(24, "hours").format().split("Z")[0],
      max_cpu_usage_ms: 0,
      max_net_usage_words: 0,
      ref_block_num: "",
      ref_block_prefix: "",
    },
  });
  const { user, multisig } = wallet;

  useEffect(() => {
    let timeoutTimer = setTimeout(() => {
      if (multisig.mode && multisig.walletTransaction) {
        multisig.walletTransaction.actions.forEach(function (action) {
          action.authorization = [{ actor: "", permission: "" }];
          formData.actions.push(action);
        });

        setFormData(formData => ({ ...formData }));
      }
    }, 500);
    return () => {
      clearTimeout(timeoutTimer);
    };
  }, []);

  useEffect(() => {    
    setFormData(formData => ({ ...formData, proposer: user ? user.actor : "" }));
  }, [user]);

  const { data: chaininfo } = useQuery(
    [`chaininfo`, formData.settings.expiration],
    () => BlockchainApiManager.getInfo(),
    {}
  );

  useEffect(() => {
    if (chaininfo && chaininfo.last_irreversible_block_id) {
      var result = refBlock(chaininfo.last_irreversible_block_id);

      setFormData(formData=>({
        ...formData,
        settings: {
          ...formData.settings,
          ref_block_num: result.ref_block_num,
          ref_block_prefix: result.ref_block_prefix,
        },
      }));
    }
  }, [chaininfo]);

  // useEffect(() => {
  //   BlockchainApiManager.getInfo().then((chaininfo) => {
  //     if (chaininfo && chaininfo.last_irreversible_block_id) {
  //       var result = refBlock(chaininfo.last_irreversible_block_id);

  //       setFormData({
  //         ...formData,
  //         settings: {
  //           ...formData.settings,
  //           ref_block_num: result.ref_block_num,
  //           ref_block_prefix: result.ref_block_prefix,
  //         },
  //       });
  //     }
  //   });
  // }, []);

  const refBlock = (block_id) => {
    var block_num = block_id.substring(0, 8);
    var block_prefix = block_id.substring(16, 16 + 8);

    var ref_block_num = parseInt(block_num, 16);
    var str = "";
    for (var i = 0; i < block_prefix.length; i = i + 2) {
      var mid = block_prefix
        .substring(i, i + 2)
        .split("")
        .reverse()
        .join("");
      str += mid;
    }
    str = str.split("").reverse().join("");
    var ref_block_prefix = parseInt(str, 16);
    var obj = {
      ref_block_prefix: ref_block_prefix,
      ref_block_num: ref_block_num & 0xffff,
    };
    return obj;
  };

  const proposeMsig = async () => {
    try {
      const signatureProvider = new JsSignatureProvider([]);
      const rpc = new JsonRpc(config.endpoints.default); //required to read blockchain state
      const api = new Api({ rpc, signatureProvider }); //required to submit transactions

      const serialized_actions = await api.serializeActions(formData.actions);

      var msigTransaction = {};
      msigTransaction.delay_sec = formData.settings.delay_sec;
      msigTransaction.actions = [];

      msigTransaction.actions.push({
        account: "eosio.msig",
        name: "propose",
        data: {
          proposer: formData.proposer,
          proposal_name: formData.proposal_name,
          requested: formData.requested,
          trx: {
            actions: serialized_actions,
            delay_sec: formData.settings.delay_sec,
            expiration: formData.settings.expiration,
            max_cpu_usage_ms: formData.settings.max_cpu_usage_ms,
            max_net_usage_words: formData.settings.max_net_usage_words,
            ref_block_num: formData.settings.ref_block_num,
            ref_block_prefix: formData.settings.ref_block_prefix,
            context_free_actions: [],
            transaction_extensions: [],
          },
        },
      });

      wallet.submitTransaction(msigTransaction);
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <>
    <Helmet>
        <title>MSIG | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="container border p-2">
          <h4>MSIG Propose</h4>
          <form>
            <div className="mb-3">
              <label className="form-label">Proposer</label>
              <input
                type="text"
                className="form-control"
                value={formData.proposer}
                onChange={(event) =>
                  setFormData(formData=>({ ...formData, proposer: event.target.value }))
                }
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Proposal Name</label>
              <input
                type="text"
                className="form-control"
                value={formData.proposal_name}
                onChange={(event) =>
                  setFormData(formData=>({
                    ...formData,
                    proposal_name: event.target.value,
                  }))
                }
              />
            </div>
            <RequestedApprovals formData={formData} setFormData={setFormData} />

            <Actions formData={formData} setFormData={setFormData} />

            <div className="mb-3">
              <label className="form-label">Transaction Settings</label>
              <button
                type="button"
                className="btn btn-primary btn-sm ms-2"
                onClick={() => {
                  formData.settings.enabled = formData.settings.enabled
                    ? false
                    : true;
                  setFormData({ ...formData });
                }}
              >
                {formData.settings.enabled ? "Hide" : "Show"}
              </button>
              {formData.settings.enabled ? (
                <div>
                  <div className="mb-3">
                    <label className="form-label">max_net_usage_words</label>
                    <input
                      type="text"
                      className="form-control"
                      value={formData.settings.max_net_usage_words}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          settings: { max_net_usage_words: event.target.value },
                        })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">max_cpu_usage_ms</label>
                    <input
                      type="text"
                      className="form-control"
                      value={formData.settings.max_cpu_usage_ms}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          settings: { max_cpu_usage_ms: event.target.value },
                        })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">delay_sec</label>
                    <input
                      type="text"
                      className="form-control"
                      value={formData.settings.delay_sec}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          settings: { delay_sec: event.target.value },
                        })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">expiration</label>
                    <input
                      type="text"
                      className="form-control"
                      value={formData.settings.expiration}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          settings: { expiration: event.target.value },
                        })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">ref_block_num</label>
                    <input
                      type="text"
                      className="form-control"
                      value={formData.settings.ref_block_num}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          settings: { ref_block_num: event.target.value },
                        })
                      }
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">ref_block_prefix</label>
                    <input
                      type="text"
                      className="form-control"
                      value={formData.settings.ref_block_prefix}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          settings: { ref_block_prefix: event.target.value },
                        })
                      }
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={proposeMsig}
            >
              Propose
            </button>
          </form>
        </div>
      </PageTransition>
    </>
  );
};

export default Msig;
