import config from "../config";
import FetchManager from "./fetchManager";


  const ApiManager = {
    getProducers() {
      return new Promise(async (resolve, reject) => {
        const producers = await FetchManager.get(config.api.url, {
          path: `${config.api.path}/producers`,
        }).catch((err) => {
          return reject(err);
        });
  
        return resolve(producers);
      });
    },
    getProxies() {
      return new Promise(async (resolve, reject) => {
        const proxies = await FetchManager.get(config.api.url, {
          path: `${config.api.path}/proxies`,
        }).catch((err) => {
          return reject(err);
        });
  
        return resolve(proxies);
      });
    },
  };
  
  export default ApiManager;