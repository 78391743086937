import Chart1 from "./components/chart1";
import Chart2 from "./components/chart2";
import Chart4 from "./components/chart4";
import Chart5 from "./components/chart5";


const ApexchartsDemo = () => {
  return (
    <>
      <div className="row col-md-12 mb-2">
        <Chart1 />
      </div>
      <div className="row col-md-12 mb-2">
        <Chart2 />
      </div> 

       <div className="container mb-2">
        <div className="row">
          {/* <div className="col-md-3">
          <Chart3 />
          </div> */}
          <div className="col-md-3"> <Chart4 /></div>
          <div className="col-md-3"> <Chart5/></div>
        </div>
      </div> 
    </>
  );
};

export default ApexchartsDemo;
