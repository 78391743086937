import { useContext, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import BlockchainApiManager from "../../../../app/services/blockchainApiManager";
import { Link } from "react-router-dom";
import DisplayActionData from "../../../../app/components/blockchain/action/display-action-data";
import { getShortTrxId } from "../../../../app/utilities/transaction";
import { SocketContext } from "../../../../app/contexts/socket-context";

const TransactionsTab = ({ chainInfo }) => {
  const socket = useContext(SocketContext);
  const [transactions, setTransactions] = useState([]);
  const [status, setStatus] = useState("started");

  const [currentItems, setCurrentItems] = useState([]);

  // useEffect(() => {
  //   var intervalTimer = setInterval(() => {
  //     setTransactions((transactions) => [
  //       { id: Math.random(), datetime: moment().utc().format() },
  //       ...transactions,
  //     ]);
  //   }, 1000);

  //   return () => {
  //     clearInterval(intervalTimer);
  //   };
  // }, []);

  // useEffect(() => {
  //   //console.log("chainInfo", chainInfo);
  //   if (!chainInfo) return;

  //   if (status === "paused") return;

  //   BlockchainApiManager.getBlock(chainInfo.head_block_num).then((block) => {
  //     setTransactions((transactions) =>
  //       [
  //         //  { id: Math.random(), datetime: moment().utc().format() },
  //         ...block.transactions,
  //         ...transactions,
  //       ].slice(0, 20)
  //     );
  //   });
  // }, [chainInfo]);

  useEffect(() => {
    if (status === "paused") {
      socket.emit("leave", "transactions");
    } else {
      socket.emit("join", "transactions");
    }
  }, [socket, status]);

  useEffect(() => {
    if (!socket) return;

    socket.emit("join", "transactions");
    socket.off("transactions").on("transactions", (recentTransactions) => {
      setTransactions((transactions) =>
        [...recentTransactions, ...transactions].slice(0, 20)
      );
    });
    return () => {
      socket.emit("leave", "transactions");
    };
  }, [socket, socket?.connected]);

  const itemsPerPage = 20;
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(transactions.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(transactions.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, transactions]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % transactions.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const toggleStatus = () => {
    setStatus((status) => {
      return status === "paused" ? "started" : "paused";
    });
  };

  return (
    <>
      <div className="row p-2">
        <div className="col-md-12">
          <button
            className={`btn btn-sm ${
              status === "started" ? "btn-primary" : "btn-success"
            } `}
            onClick={() => toggleStatus()}
          >
            {status === "started" ? "Pause" : "Play"}
          </button>
          &nbsp; Live WAX Transactions
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-sm " style={{ tableLayout: "fixed" }}>
          <thead>
            <tr>
              <th scope="col" style={{ width: "100px" }}>
                TX ID
              </th>
              <th scope="col" style={{ width: "150px" }}>
                Account
              </th>
              <th scope="col" style={{ width: "150px" }}>
                Action
              </th>
              <th scope="col">Data</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((transaction, index) => (
              <tr key={index}>
                <td>
                  {transaction.trx && transaction.trx.id ? (
                    <Link to={`/transaction/${transaction.trx.id}`}>
                      {getShortTrxId(transaction.trx.id)}
                    </Link>
                  ) : (
                    ""
                  )}
                </td>
                <td>
                  {transaction.trx &&
                  transaction.trx.transaction &&
                  transaction.trx.transaction.actions[0] ? (
                    <Link
                      to={`/account/${transaction.trx.transaction.actions[0].account}`}
                    >
                      {transaction.trx.transaction.actions[0].account}
                    </Link>
                  ) : null}
                </td>
                <td>
                  {transaction.trx &&
                    transaction.trx.transaction &&
                    transaction.trx.transaction.actions[0].name}
                </td>
                <td>
                  {/* {transaction.trx &&
                  transaction.trx.transaction &&
                  transaction.trx.transaction.actions[0] ? (
                    <>
                      <Link
                        to={`/account/${transaction.trx.transaction.actions[0].account}`}
                      >
                        {transaction.trx.transaction.actions[0].account}
                      </Link>{" "}
                      (contract) executed the{" "}
                      <strong>
                        "{transaction.trx.transaction.actions[0].name}"
                      </strong>{" "}
                      action
                      <Link to={`/transaction/${transaction.trx.id}`}>
                        (view data in the transaction page)
                      </Link>
                    </>
                  ) : null} */}

                  {transaction.trx &&
                  transaction.trx.transaction &&
                  transaction.trx.transaction.actions[0] ? (
                    <DisplayActionData
                      action={transaction.trx.transaction.actions[0]}
                    />
                  ) : null}

                  {/* {transaction.trx &&
                    transaction.trx.transaction &&
                    JSON.stringify(transaction.trx.transaction.actions[0].data)} */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <ReactPaginate
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        className={"pagination"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
      /> */}
    </>
  );
};

export default TransactionsTab;
