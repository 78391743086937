import { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import PageTransition from "../../../../app/animations/page-transition";
import WalletTransactionAlert from "../../../../app/components/wallet-transaction-alert";
import config from "../../../../app/config";
import { WalletContext } from "../../../../app/contexts/wallet-context";
import ApiManager from "../../../../app/services/apiManager";
//import producersData from "../../../../dummy-data/producers.json";

const Producers = () => {
  const wallet = useContext(WalletContext);
  const { user, setTransactionAlert,transactionAlert } = wallet;
  //const [producers, setProducers] = useState(producersData);
  const [voteProducers, setVoteProducers] = useState([]);

  const {
    //  isLoading,
    // error,
    data: producers,
    refetch,
  } = useQuery([`producers`], () => ApiManager.getProducers(), {
    enabled: false,
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      refetch();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(()=>{
    if (voteProducers.length > 30) {
      setTransactionAlert({
        type: "warning",
        message: "Please select a maximum of 30 producers to vote",
      });         
    }else{    
      setTransactionAlert(null)
    }   
  },[voteProducers])

  const addVoteProducer = (producer_owner) => {    
    setVoteProducers((voteProducers) => [...voteProducers, producer_owner]); // or push
  };

  const removeVoteProducer = (producer_owner) => {
    setVoteProducers((voteProducers) =>
      voteProducers.filter((voteProducer) => producer_owner !== voteProducer)
    );
  };

  const handleClick = () => {
    var walletTransaction = {};
    walletTransaction.type = "voteproducer";

    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio",
      name: "voteproducer",
      data: {
        voter: user ? user.actor : "",
        proxy: "",
        producers: voteProducers,
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  return (
    <>
      <Helmet>
        <title>Vote Producers | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="container border p-2">
          <h4>Selected Producers ({voteProducers.length}/30)</h4>

          {voteProducers.map((voteProducer, index) => (
            <button
              type="button"
              className="btn btn-secondary btn-sm  m-2"
              key={index}
              onClick={() => {
                removeVoteProducer(voteProducer);
              }}
            >
              {voteProducer}&nbsp;
              <i className="fa fa-close"></i>
            </button>
          ))}
          <button
            type="button"
            className="btn btn-primary btn-sm m-2 d-block"
            onClick={() => handleClick()}
          >
            Vote
          </button>
          <WalletTransactionAlert />
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Select</th>
                <th scope="col">Rank</th>
                <th scope="col">Account</th>
                <th scope="col">Total Votes</th>
                <th scope="col">Location</th>
              </tr>
            </thead>
            <tbody>
              {!producers &&
                Array(5)
                  .fill(0)
                  .map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
              {producers &&
                producers.map((producer, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        name="voteProducers"
                        checked={
                          voteProducers.includes(producer.owner) ? true : false
                        }
                        onClick={(event) => {
                          if (voteProducers.includes(producer.owner)) {
                            removeVoteProducer(producer.owner);
                          } else {
                            addVoteProducer(producer.owner);
                          }
                        }}
                      />
                    </td>
                    <td>{producer.rank}</td>
                    <td>
                      <img
                        src={`${
                          producer.logo
                            ? `${config.imagekit.default_url_endpoint}/${producer.logo}`
                            : "/no-image.png"
                        }`}
                        alt=""
                        width={30}
                        height={30}
                      />
                      <Link to={`/account/${producer.owner}`}>
                        {producer.owner}
                      </Link>
                    </td>
                    <td>{producer.actual_votes.toLocaleString()}</td>
                    <td>{producer.location_name}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </PageTransition>
    </>
  );
};

export default Producers;
