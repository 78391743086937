import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import PageTransition from "../../../app/animations/page-transition";
import ImageLoader from "../../../app/components/image-loader";
import BlockchainApiManager from "../../../app/services/blockchainApiManager";
import {
  getAssetId,
  getType,
  getAuthor,
  getCollectionName,
  getImage,
  getName,
  getVideo,
} from "../../../app/utilities/nft";

const NftAssets = () => {
  let params = useParams();
  const [authors, setAuthors] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [filterAuthors, setFilterAuthors] = useState([]);
  const [filteredNfts, setFilteredNfts] = useState([]);

  const {
    isLoading,
    error,
    data: nfts,
  } = useQuery(
    [`nfts`, params.account],
    () => BlockchainApiManager.getNfts(params.account),
    {}
  );

  useEffect(() => {
    if (!nfts) return;

    let authors = [];
    nfts.data.forEach((nft) => {
      var newAuthor =
        nft.contract === "atomicassets" ? nft.collection.author : nft.author;
      var author = authors.find((author) => author === newAuthor);
      if (!author) {
        authors.push(newAuthor);
      }
    });

    setAuthors(authors);
  }, [nfts]);

  useEffect(() => {
    if (!nfts) return;

    const newFilteredNfts = nfts.data.filter((nft) => {
      var author =
        nft.contract === "atomicassets" ? nft.collection.author : nft.author;
      return filterAuthors.length ? filterAuthors.includes(author) : true;
    });
    //console.log('newFilteredNfts',newFilteredNfts)
    setFilteredNfts(newFilteredNfts);
  }, [nfts, filterAuthors]);

  const itemsPerPage = 10;
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    if (!nfts) return;

    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(filteredNfts.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredNfts.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, filteredNfts]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % filteredNfts.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      {isLoading ? <p>Loading...</p> : null}

      {nfts ? (
        <>
          <div className="container border p-2">
            <h4>
              Total {filteredNfts.length}/{nfts.total} NFTs
            </h4>
            <h4>Filter by Collection</h4>
            {authors.map((author, index) => (
              <button
                type="button"
                className={`btn ${
                  filterAuthors.includes(author)
                    ? "btn-primary"
                    : "btn-secondary"
                } btn-sm  m-2`}
                key={index}
                onClick={() => {
                  setFilterAuthors((filterAuthors) => {
                    if (filterAuthors.includes(author)) {
                      return filterAuthors.filter(
                        (filterAuthor) => filterAuthor !== author
                      );
                    }

                    return [...filterAuthors, author];
                  });
                }}
              >
                {author}
              </button>
            ))}

            <div className="row">
              <div className="col-md-12">
                {currentItems.map((nft, index) => (
                  <div
                    className="card"
                    style={{
                      width: "250px",
                      height: "465px",
                      margin: "5px",
                      display: "inline-table",
                    }}
                  >
                    {getImage(nft) ? (
                      <>
                        <ImageLoader
                          src={getImage(nft)}
                          style={{ height: "250px", verticalAlign: "unset" }}
                          className="card-img-top"
                        />
                        {/* <img
                        src={getImage(nft)}
                        alt=""
                        style={{ height: "250px",verticalAlign:'unset' }}
                        className="card-img-top"
                        onError={(e)=>{e.target.onerror = null; e.target.src="/no-image.png"}}
                      /> */}
                      </>
                    ) : null}
                    {!getImage(nft) && getVideo(nft) ? (
                      <video
                        controls
                        style={{ height: "250px" }}
                        className="card-img-top"
                      >
                        <source src={getVideo(nft)} type="video/mp4" />
                      </video>
                    ) : null}
                    {!getImage(nft) && !getVideo(nft) ? (
                      <img
                        src={`/no-image.png`}
                        alt=""
                        style={{ height: "250px", verticalAlign: "unset" }}
                        className="card-img-top"
                      />
                    ) : null}

                    <div className="card-body">
                      <h5 className="card-title">
                        <Link
                          to={`/nft/${getType(nft)}/${getAssetId(
                            nft
                          )}?account=${params.account}`}
                        >
                          {getName(nft)}
                        </Link>
                      </h5>
                      <ul class="list-group list-group-flush">
                        <li class="list-group-item">ID: {getAssetId(nft)}</li>
                        <li class="list-group-item">
                          Collection: {getCollectionName(nft)}
                        </li>
                        <li class="list-group-item">
                          Author:{" "}
                          <Link to={`/account/${getAuthor(nft)}`}>
                            {getAuthor(nft)}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">ID</th>
                    <th scope="col">Collection</th>
                    <th scope="col">Author</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((nft, index) => (
                    <tr key={index}>
                      <td>
                        {getImage(nft) ? (
                          <img
                            src={getImage(nft)}
                            alt=""
                            style={{ width: "50px" }}
                          />
                        ) : null}
                        {!getImage(nft) && getVideo(nft) ? (
                          <video controls style={{ width: "50px" }}>
                            <source src={getVideo(nft)} type="video/mp4" />
                          </video>
                        ) : null}
                        {!getImage(nft) && !getVideo(nft) ? (
                          <img
                            src={`/no-image.png`}
                            alt=""
                            style={{ width: "50px" }}
                          />
                        ) : null}
                        &nbsp;
                        <Link
                          to={`/nft/${getType(nft)}/${getAssetId(
                            nft
                          )}?account=${params.account}`}
                        >
                          {getName(nft)}
                        </Link>
                      </td>
                      <td>{getAssetId(nft)}</td>
                      <td>{getCollectionName(nft)}</td>
                      <td>
                        <Link to={`/account/${getAuthor(nft)}`}>
                          {getAuthor(nft)}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div> */}
            <ReactPaginate
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              className={"pagination"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default NftAssets;
