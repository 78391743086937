import { Link } from "react-router-dom";

const DisplayActionData = ({ action, account_name }) => {
  if (action.account === "eosio" && action.name === "buyram") {
    return (
      <>
        <Link to={`/account/${action.data.payer}`}>{action.data.payer}</Link>{" "}
        bought {action.data.quant} WAX of RAM for{" "}
        <Link to={`/account/${action.data.receiver}`}>
          {action.data.receiver}
        </Link>
      </>
    );
  }
  if (action.account === "eosio" && action.name === "buyrambytes") {
    return (
      <>
        <Link to={`/account/${action.data.payer}`}>{action.data.payer}</Link>{" "}
        bought {action.data.quant} bytes of RAM for{" "}
        <Link to={`/account/${action.data.receiver}`}>
          {action.data.receiver}
        </Link>
      </>
    );
  }

  if (action.account === "eosio" && action.name === "claimrewards") {
    return (
      <>
        <Link to={`/account/${action.data.owner}`}>{action.data.owner}</Link>{" "}
        claimed validator rewards
      </>
    );
  }

  if (action.account === "eosio" && action.name === "delegatebw") {
    return (
      <>
        <Link to={`/account/${action.data.from}`}>{action.data.from}</Link>{" "}
        staked {action.data.stake_cpu_quantity} WAX of CPU and{" "}
        {action.data.stake_net_quantity} WAX of NET from{" "}
        <Link to={`/account/${action.data.receiver}`}>
          {action.data.receiver}
        </Link>
      </>
    );
  }

  if (action.account === "eosio" && action.name === "undelegatebw") {
    return (
      <>
        <Link to={`/account/${action.data.from}`}>{action.data.from}</Link>{" "}
        unstaked {action.data.unstake_cpu_quantity} WAX of CPU and{" "}
        {action.data.unstake_net_quantity} WAX of NET from{" "}
        <Link to={`/account/${action.data.receiver}`}>
          {action.data.receiver}
        </Link>
      </>
    );
  }

  if (action.account === "eosio" && action.name === "sellram") {
    return (
      <>
        <Link to={`/account/${action.data.account}`}>
          {action.data.account}
        </Link>{" "}
        sold {action.data.bytes} bytes of RAM{" "}
      </>
    );
  }

  if (action.account === "eosio" && action.name === "voteproducer") {
    if (action.data.proxy !== "") {
      return (
        <>
          <Link to={`/account/${action.data.voter}`}>{action.data.voter}</Link>{" "}
          voted for proxy{" "}
          <Link to={`/account/${action.data.proxy}`}>{action.data.proxy}</Link>
        </>
      );
    } else {
      return (
        <>
          <Link to={`/account/${action.data.voter}`}>{action.data.voter}</Link>{" "}
          voted for producers{" "}<br/>
          {action.data.producers.map((producer) => (
            <><span style={{display:'inline-block',width:'150px'}} >
              <Link to={`/account/${producer}`}>{producer}</Link>
              </span>
            </>
          ))}
        </>
      );
    }
  }

  if (action.name === "transfer") {
    if (account_name) {
      if (action.data.from === account_name) {
        return (
          <>
            <Link to={`/account/${action.data.from}`}>{action.data.from}</Link>{" "}
            sent {action.data.quantity} to{" "}
            <Link to={`/account/${action.data.to}`}>{action.data.to}</Link>
            {action.data.memo ? (
              <span className="d-block">Memo: {action.data.memo}</span>
            ) : null}
          </>
        );
      } else {
        return (
          <>
            <Link to={`/account/${action.data.to}`}>{action.data.to}</Link>{" "}
            received {action.data.quantity} from{" "}
            <Link to={`/account/${action.data.from}`}>{action.data.from}</Link>
            {action.data.memo ? (
              <span className="d-block">Memo: {action.data.memo}</span>
            ) : null}
          </>
        );
      }
    } else {
    }
  }

  return (
    <>
      {Object.keys(action.data).map((dataKey, index) => (
        <div key={index}>
          <span className="fw-bold">{dataKey}: </span>
          {typeof action.data[dataKey] === "object" &&
          Array.isArray(action.data[dataKey]) ? (
            <>
              {action.data[dataKey].map((dataValue, valueIndex) => (
                <div key={valueIndex}>
                  <span>{JSON.stringify(dataValue)}</span>
                </div>
              ))}
            </>
          ) : null}

          {typeof action.data[dataKey] === "object" &&
          !Array.isArray(action.data[dataKey]) ? (
            <span>{JSON.stringify(action.data[dataKey])}</span>
          ) : null}

          {typeof action.data[dataKey] !== "object" ? (
            <span>{action.data[dataKey]}</span>
          ) : null}
        </div>
      ))}
    </>
  );
};

export default DisplayActionData;
