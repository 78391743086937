import { useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import PageTransition from "../../app/animations/tab-transition";
import { WalletContext } from "../../app/contexts/wallet-context";
import AccountSummary from "./components/account-summary";

const Wallet = () => {
  const wallet = useContext(WalletContext);
  const { user  } = wallet;
  return (
    <>
    <PageTransition>
      <div className="row">
        <div className="col-md-3">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link className="nav-link" to="/wallet/transfer">
                Transfer Tokens
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/wallet/resources">
                Manage CPU / NET
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/wallet/ram">
                Buy / Sell RAM
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/wallet/permissions">
                Manage keys and permissions
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/wallet/msig">
                MSIG
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/wallet/vote">
                Vote
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-md-9">
          <h4>{user?`Connected wallet to ${user.actor}`:`Wallet`}</h4>
          <AccountSummary/>
          <Outlet />
        </div>
      </div>
      </PageTransition>
    </>
  );
};

export default Wallet;
