import { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import PageTransition from "../../../../app/animations/page-transition";
import WalletTransactionAlert from "../../../../app/components/wallet-transaction-alert";
import config from "../../../../app/config";
import { WalletContext } from "../../../../app/contexts/wallet-context";
import { tokenFormat } from "../../../../app/utilities/format";

const Buy = () => {
  const wallet = useContext(WalletContext);
  const { user } = wallet;

  const [formData, setFormData] = useState({
    payer: "",
    receiver: "",
    quantity: 0,
  });

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      payer: user ? user.actor : "",
      receiver: user ? user.actor : "",
    }));
  }, [user]);

  const calculateBytes = () => {
    let bytes = 0;
    if (formData.quantity > 0) {
      bytes = Math.round(formData.quantity * 1292);
    }

    return bytes;
  };

  const handleClick = async () => {
    var walletTransaction = {};
    walletTransaction.type = "buyram";

    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio",
      name: "buyram",
      data: {
        payer: formData.payer,
        receiver: formData.receiver,
        quant: `${tokenFormat(formData.quantity)} ${config.token_symbol}`,
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  return (
    <>
     <Helmet>
        <title>Buy RAM | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="container border p-2">
          <form>
            <div className="mb-3">
              <label className="form-label">Receiver of Ram</label>
              <input
                type="text"
                className="form-control"
                value={formData.receiver}
                onChange={(event) =>
                  setFormData({ ...formData, receiver: event.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label className="form-label">
                Amount of RAM to buy (in WAX)
              </label>
              <input
                type="number"
                className="form-control"
                value={formData.quantity}
                onChange={(event) =>
                  setFormData({ ...formData, quantity: event.target.value })
                }
              />
              <p className="text-muted">≈ {calculateBytes()} Bytes</p>
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleClick()}
            >
              {formData.quantity > 0
                ? `Buy ${formData.quantity} ${config.token_symbol} of RAM`
                : `Buy RAM`}
            </button>
          </form>
        </div>
        <WalletTransactionAlert />
      </PageTransition>
    </>
  );
};

export default Buy;
