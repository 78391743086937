const moment = require("moment");

const Footer = () => {
  return (
      <div className="container">
    <footer className="row row-cols-5 py-5 my-5 border-top">
      <div className="col">
        <a
          href="/"
          className="d-flex align-items-center mb-3 link-dark text-decoration-none"
        >
          WAX Explorer
        </a>
        <p className="text-muted">© {moment().format('Y')}</p>
      </div>
      <div className="col"></div>
      <div className="col">
        <h5>Wallets</h5>
        <ul className="nav flex-column">
          <li className="nav-item mb-2">
            <a href="#" className="nav-link p-0 text-muted">
              Anchor
            </a>
          </li>
          <li className="nav-item mb-2">
            <a href="#" className="nav-link p-0 text-muted">
              SimpleEOS
            </a>
          </li>
          <li className="nav-item mb-2">
            <a href="#" className="nav-link p-0 text-muted">
              TokenPocket
            </a>
          </li>          
        </ul>
      </div>
      <div className="col">
        <h5>APIs</h5>
        <ul className="nav flex-column">
          <li className="nav-item mb-2">
            <a href="#" className="nav-link p-0 text-muted">
              Grey Mass
            </a>
          </li>
          <li className="nav-item mb-2">
            <a href="#" className="nav-link p-0 text-muted">
              Hyperion
            </a>
          </li>
         
        </ul>
      </div>
      <div className="col">
        <h5>Company</h5>
        <ul className="nav flex-column">
          <li className="nav-item mb-2">
            <a href="#" className="nav-link p-0 text-muted">
              About
            </a>
          </li>
          <li className="nav-item mb-2">
            <a href="#" className="nav-link p-0 text-muted">
              FAQ
            </a>
          </li>         
        </ul>
      </div>
    </footer>
    </div>
  );
};

export default Footer;
