import { Link, Outlet, useLocation } from "react-router-dom";
import PageTransition from "../../../app/animations/page-transition";

const Ram = () => {
  const location = useLocation();

  return (
    <>
      <PageTransition>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              className={`nav-link ${
                ["/wallet/ram", "/wallet/ram/buy"].includes(
                  location.pathname
                )
                  ? "active"
                  : ""
              }`}
              to="/wallet/ram/buy"
            >
              Buy RAM
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                ["/wallet/ram/sell"].includes(location.pathname)
                  ? "active"
                  : ""
              }`}
              to="/wallet/ram/sell"
            >
              Sell RAM
            </Link>
          </li>        
        </ul>
        <Outlet />
      </PageTransition>
    </>
  );
};

export default Ram;
