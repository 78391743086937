import Helmet from "react-helmet";
import PageTransition from "../../../app/animations/page-transition";

const Permissions = () => {
  return (
    <>
    <Helmet>
        <title>Permissions | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="container border p-2">
          <h4>Permission</h4>
          <form>
            <div className="mb-3">
              <label className="form-label">Set new Active Key</label>
              <input type="text" className="form-control" />
            </div>
            <div className="mb-3">
              <label className="form-label">Set new Owner Key</label>
              <input type="text" className="form-control" />
            </div>
            <button type="button" className="btn btn-primary">
              Update Keys
            </button>
          </form>
        </div>
      </PageTransition>
    </>
  );
};

export default Permissions;
