import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import DefaultLayout from "./layouts/default";
import Home from "./views/home";
import Block from "./views/block";
import Transaction from "./views/transaction";
import Account from "./views/account";
import Wallet from "./views/wallet";
import Transfer from "./views/wallet/transfer";
import Resources from "./views/wallet/resources";
import Permissions from "./views/wallet/permissions";
import Msig from "./views/wallet/msig";
import MsigDetail from "./views/msig";

import "./App.css";
import Stake from "./views/wallet/resources/stake";
import Unstake from "./views/wallet/resources/unstake";
import Refund from "./views/wallet/resources/refund";
import Vote from "./views/wallet/vote/vote";
import Producers from "./views/wallet/vote/producers";
import Proxies from "./views/wallet/vote/proxies";
import Nft from "./views/nft";
import Ram from "./views/wallet/ram/ram";
import Buy from "./views/wallet/ram/buy";
import Sell from "./views/wallet/ram/sell";
import ChartsDemo from "./views/charts";
import HighchartsDemo from "./views/charts/highcharts";
import ApexchartsDemo from "./views/charts/apexcharts";
import Helmet from "react-helmet";
import ScrollToTop from "./app/components/scroll-to-top";

function App() {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <title>WAX Block Explorer and Wallet</title>
      </Helmet>
      <ScrollToTop />
      <AnimatePresence exitBeforeEnter initial={false}>
        <Routes location={location}>
          <Route path="/" element={<DefaultLayout />}>
            <Route index element={<Home />} />
            <Route path="block/:id" element={<Block />} />
            <Route path="transaction/:id" element={<Transaction />} />
            <Route path="account/:account" element={<Account />} />
            <Route path="wallet" element={<Wallet />}>
              <Route index element={<Transfer />} />
              <Route path="transfer" element={<Transfer />} />
              <Route path="resources" element={<Resources />}>
                <Route index element={<Stake />} />
                <Route path="stake" element={<Stake />} />
                <Route path="unstake" element={<Unstake />} />
                <Route path="refund" element={<Refund />} />
              </Route>
              <Route path="vote" element={<Vote />}>
                <Route index element={<Producers />} />
                <Route path="producers" element={<Producers />} />
                <Route path="proxies" element={<Proxies />} />
              </Route>
              <Route path="ram" element={<Ram />}>
                <Route index element={<Buy />} />
                <Route path="buy" element={<Buy />} />
                <Route path="sell" element={<Sell />} />
              </Route>
              <Route path="permissions" element={<Permissions />} />
              <Route path="msig" element={<Msig />} />
            </Route>
            <Route path="nft/:assetType/:assetId" element={<Nft />} />
            <Route path="msig/:scope/:name" element={<MsigDetail />} />
            <Route path="charts" element={<ChartsDemo />}>
              <Route index element={<HighchartsDemo />} />
              <Route path="highcharts" element={<HighchartsDemo />} />
              <Route path="apexcharts" element={<ApexchartsDemo />} />
            </Route>
          </Route>
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
