import { useContext, useEffect } from "react";
import { WalletContext } from "../contexts/wallet-context";
import parse from "html-react-parser";

const WalletTransactionAlert = () => {
  const wallet = useContext(WalletContext);
  const { transactionAlert, setTransactionAlert } = wallet;

  useEffect(() => {
    return () => {
      setTransactionAlert(null);
    };
  }, []);

  if (!transactionAlert) return;

  if (transactionAlert.type === "error") {
    return (
      <div className={`alert alert-danger`} role="alert">
        <h4>Error</h4>
        {parse(transactionAlert.message)}
      </div>
    );
  }

  if (transactionAlert.type === "warning") {
    return (
      <div className={`alert alert-warning`} role="alert">
        <h4>Warning</h4>
        {parse(transactionAlert.message)}
      </div>
    );
  }

  if (transactionAlert.type === "info") {
    return (
      <div className={`alert alert-info`} role="alert">
        <h4>Info</h4>
        {parse(transactionAlert.message)}
      </div>
    );
  }

  return (
    <div className={`alert alert-success`} role="alert">
      <h4>Success</h4>
      {parse(transactionAlert.message)}
    </div>
  );
};

export default WalletTransactionAlert;
