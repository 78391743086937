import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

const Chart1 = () => {
  const [options, setOptions] = useState({
          
    series: [44, 55, 67],
    options: {
      
      chart: {
        height: 350,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: '22px',
            },
            value: {
              fontSize: '16px',
            },
            total: {
              show: true,
              label: 'Utilization',
              formatter: function (w) {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                return `79%`
              }
            }
          }
        }
      },
      labels: ['RAM', 'CPU', 'NET'],
    },
  
  
  });
  return (
    <>
      <ReactApexChart
        options={options.options}
        series={options.series}
        type="radialBar"
        height={350}
      />
    </>
  );
};

export default Chart1;
