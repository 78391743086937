import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import moment from "moment";
import BlockchainApiManager from "../../../../app/services/blockchainApiManager";
import ClaimBids from "./claimbids";
import CurrentBids from "./currentbids";

const NamebidsTab = ({parentActiveTab}) => {
  const [activeTab, setActiveTab] = useState("currentBids");
  const [currentBids, setCurrentBids] = useState([]);
  const [claimBids, setClaimBids] = useState([]);

  const {
    isLoading,    
    error,
    data: nameBids,
  } = useQuery(
    [`nameBids`],
    () =>
      BlockchainApiManager.getTableRows({
        code: "eosio",
        scope: "eosio",
        table: "namebids",
        limit: -1,
      }),
    {
     
    }
  );


  useEffect(() => {
    if (!nameBids) return;

    let currentBidsRows = [...nameBids.rows];
    currentBidsRows = currentBidsRows.filter((row) => row.high_bid >= 0);
    currentBidsRows.sort((rowA, rowB) => rowB.high_bid - rowA.high_bid);

    setCurrentBids(currentBidsRows);

    let claimBidsRows = [...nameBids.rows];
    claimBidsRows = claimBidsRows.filter((row) => row.high_bid < 0);
    claimBidsRows.sort(
      (rowA, rowB) =>
        moment(rowB.last_bid_time).unix() - moment(rowA.last_bid_time).unix()
    );

    setClaimBids(claimBidsRows);
  }, [nameBids]);

  if (error) {
    return (
      <>
        <p>Error fetching</p>
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <p>Loading...</p>
      </>
    );
  }

  return (
    <>
      <div className="container mt-5">
        <ul className="nav nav-tabs" id="namebidsTab">
          <li className="nav-item">
            <button
              className={`nav-link ${
                activeTab === "currentBids" ? "active" : ""
              }`}
              id="currentBids-tab"
              data-bs-toggle="tab"
              data-bs-target="#currentBids"
              type="button"
              role="tab"
              aria-controls="currentBids"
              aria-selected="true"
              onClick={() => setActiveTab("currentBids")}
            >
              Current Bids
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${
                activeTab === "claimBids" ? "active" : ""
              }`}
              id="claimBids-tab"
              data-bs-toggle="tab"
              data-bs-target="#claimBids"
              type="button"
              role="tab"
              aria-controls="claimBids"
              aria-selected="true"
              onClick={() => setActiveTab("claimBids")}
            >
               Bids to claim
            </button>
          </li>
        </ul>
        <div
          className="tab-content"
          id="namebidsTabContent"
          style={{ height: "auto", minHeight: "800px" }}
        >
          <div
            className={`tab-pane fade show active`}
            id="currentBids"
            role="tabpanel"
            aria-labelledby="currentBids-tab"
          >
            <CurrentBids currentBids={currentBids} />
            {/* {activeTab === "currentBids" ? (
              <CurrentBids currentBids={currentBids} />
            ) : null} */}
          </div>
          <div
            className={`tab-pane fade show`}
            id="claimBids"
            role="tabpanel"
            aria-labelledby="claimBids-tab"
          >
            <ClaimBids claimBids={claimBids} />
            {/* {activeTab === "claimBids" ? (
              <ClaimBids claimBids={claimBids} />
            ) : null} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NamebidsTab;
