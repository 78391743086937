export const config = {
  network: "wax",
  token_symbol: "WAX",
  token_precision: 8,
  endpoints: {
    default: "https://api.wax.alohaeos.com",
    state: {      
      "https://api-wax.eosauthority.com": 100,
      "https://wax.eosphere.io": 80,
      "https://api.wax.alohaeos.com":100
    },
    block: {     
      "https://wax.eosdublin.io": 100,
      "https://api-wax.eosauthority.com": 100,
      "https://api-wax.eosarabia.net": 90,
      "https://api.wax.alohaeos.com":100
    },
    transaction: {      
      "https://wax.eosphere.io": 100,
      "https://api-wax.eosauthority.com": 100,
      "https://api-wax.eosarabia.net": 70,
      "https://api.wax.alohaeos.com":100
    },
    history: {
      "https://wax.eosphere.io": 100,
      "https://api-wax.eosauthority.com": 100,
      "https://api.wax.alohaeos.com":100
    },
    lightapi: {
      "https://lightapi.eosamsterdam.net": 100,
    },
    nfts: {
      simpleassets: "https://wax.api.simpleassets.io",
      atomicassets: "https://wax.api.atomicassets.io",
    },
  },
  imagekit: {
    default_url_endpoint: "https://ik.imagekit.io/waxblock",
  },
  socket: {
    url: process.env.REACT_APP_SOCKET_URL || "http://127.0.0.1:8080",
    path: "/socket",
  },
  api: {
    url: process.env.REACT_APP_API_URL || "http://127.0.0.1:8080",
    path: "/api/v1",
  },
};

export default config;
