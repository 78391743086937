import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import highchartsSolidGauge from "highcharts/modules/solid-gauge";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";

highchartsMore(Highcharts);
highchartsSolidGauge(Highcharts);

const ResourcePercentage = ({ account }) => {
  let net_percentage = parseFloat(
    ((account.net_limit.used / account.net_limit.max) * 100).toFixed(0)
  );
  net_percentage =
    isNaN(net_percentage) || net_percentage < 0 || net_percentage > 100
      ? 100
      : net_percentage;

  let cpu_percentage = parseFloat(
    ((account.cpu_limit.used / account.cpu_limit.max) * 100).toFixed(0)
  );
  cpu_percentage =
    isNaN(cpu_percentage) || cpu_percentage < 0 || cpu_percentage > 100
      ? 100
      : cpu_percentage;

  let ram_percentage = parseFloat(
    ((account.ram_usage / account.ram_quota) * 100).toFixed(0)
  );
  ram_percentage =
    isNaN(ram_percentage) || ram_percentage < 0 || ram_percentage > 100
      ? 100
      : ram_percentage;

  let total_percentage = (
    (ram_percentage + cpu_percentage + net_percentage) /
    3
  ).toFixed(0);
  total_percentage = total_percentage > 100 ? 100 : total_percentage;

  const [options, setOptions] = useState({
    chart: {
      type: "solidgauge",
      events: {
        load: function () {
          // let sum = 0;
          // this.series.map((el) => {
          //   sum += el.data[0].y;
          // });
          var chart = this;
          if (!chart.lbl) {
            chart.lbl = chart.renderer
              .label("", (this.chartWidth - 80) / 2, this.plotHeight / 2 - 15)
              // .attr({
              //   padding: 10,
              //   r: 10,
              // })
              .css({
                color: "#fff",
                fontSize: "16px",
              })
              .add();
          }
          chart.lbl.show().attr({
            // text:
            //   '<span style="font-size:.8em">Utilization</span><br> <span style="font-weight:bold;font-size:1.4em;color:#ff9933">' +
            //   parseFloat((sum / 3).toFixed(0)) +
            //   "%" +
            //   "</span>",
            text:
              "Utilization" +
              '<br><span style="font-size:2em; color:' +
              "#ff9933" +
              ';  font-weight: bold">' +
              total_percentage +
              "%" +
              "</span>",
          });
        },
      },
    },
    credits: {
      enabled: false,
    },
    title: null,
    // title: {
    //   text: "Resource",
    //   style: {
    //     fontSize: "24px",
    //   },
    // },
    tooltip: {
      borderWidth: 0,
      backgroundColor: "none",
      followPointer: false,
      hideDelay: 0,
      shadow: false,
      style: {
        color: "#fff",
        fontSize: "16px",
      },
      useHTML: true,
      formatter: function () {
        var chart = this.series.chart;
        setTimeout(() => {
          if (chart.lbl) {
            chart.lbl.hide();
          }
        }, 1);
        return (
          this.series.name +
          '<br><span style="font-size:2em; color:' +
          this.color +
          ';  font-weight: bold">' +
          this.y +
          "%" +
          "</span>"
        );
      },
      positioner: function (labelWidth) {
        return {
          x: (this.chart.chartWidth - labelWidth) / 2,
          y: this.chart.plotHeight / 2 - 15,
        };
      },
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          // Track for NET
          outerRadius: "112%",
          innerRadius: "88%",
          backgroundColor: Highcharts.Color(Highcharts.getOptions().colors[0])
            .setOpacity(0.3)
            .get(),
          borderWidth: 0,
        },
        {
          // Track for CPU
          outerRadius: "87%",
          innerRadius: "63%",
          backgroundColor: Highcharts.Color(Highcharts.getOptions().colors[1])
            .setOpacity(0.3)
            .get(),
          borderWidth: 0,
        },
        {
          // Track for RAM
          outerRadius: "62%",
          innerRadius: "38%",
          backgroundColor: Highcharts.Color(Highcharts.getOptions().colors[2])
            .setOpacity(0.3)
            .get(),
          borderWidth: 0,
        },
      ],
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: [],
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
        linecap: "round",
        stickyTracking: false,
        rounded: true,
      },
      series: {
        point: {
          events: {
            mouseOver: function () {
              var chart = this.series.chart;
              if (chart.lbl) {
                chart.lbl.hide();
              }
            },
          },
        },
        events: {
          mouseOut: function () {
            // let sum = 0;
            // this.chart.series.map((el) => {
            //   sum += el.data[0].y;
            // });
            var chart = this.chart;
            if (!chart.lbl) {
              chart.lbl = chart.renderer
                .label(
                  "",
                  (this.chart.chartWidth - 80) / 2,
                  this.chart.plotHeight / 2 - 15
                )
                // .attr({
                //   padding: 10,
                //   r: 10,
                // })
                .css({
                  color: "#fff",
                  fontSize: "16px",
                })
                .add();
            }
            setTimeout(function () {
              chart.lbl.show().attr({
                // text:
                //   '<span style="font-size:.8em">Utilization</span><br> <span style="font-weight:bold;font-size:1.4em;color:#ff9933">' +
                //   parseFloat((sum / 3).toFixed(0)) +
                //   "%" +
                //   "</span>",
                text:
                  "Utilization" +
                  '<br><span style="font-size:2em; color:' +
                  "#ff9933" +
                  ';  font-weight: bold">' +
                  total_percentage +
                  "%" +
                  "</span>",
              });
            }, 0);
          },
        },
      },
    },
    series: [
      {
        name: "NET",
        data: [
          {
            color: Highcharts.getOptions().colors[0],
            radius: "112%",
            innerRadius: "88%",
            y: net_percentage,
          },
        ],
      },
      {
        name: "CPU",
        data: [
          {
            color: Highcharts.getOptions().colors[1],
            radius: "87%",
            innerRadius: "63%",
            y: cpu_percentage,
          },
        ],
      },
      {
        name: "RAM",
        data: [
          {
            color: Highcharts.getOptions().colors[2],
            radius: "62%",
            innerRadius: "38%",
            y: ram_percentage,
          },
        ],
      },
    ],
  });

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ResourcePercentage;
