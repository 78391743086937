import { Link } from "react-router-dom";
import Search from "./search";
import ManageAccounts from "./manage-accounts";
import { useContext } from "react";
import { WalletContext } from "../../app/contexts/wallet-context";

const TopNavBar = () => {
  const wallet = useContext(WalletContext);
  const { user } = wallet;

  return (
    <header className="p-2 bg-dark ">
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <Link
            to="/"
            className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none"
          >
            WAX Block
          </Link>
          <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
            <li>
              <Link to="/" className="nav-link  text-white">
                Home
              </Link>
            </li>
            <li>
            <Link to={`/account/${user?user.actor:'wam'}`} className="nav-link  text-white">
                Account
              </Link>
            </li>
            <li>
              <Link to="/wallet" className="nav-link text-white">
                Wallet
              </Link>
            </li>
            {/* <li>
              <a href="#" className="nav-link  text-white">
                dApps
              </a>
            </li> */}
          </ul>
          <Search />
          <div className="text-end">
            <ManageAccounts/>
            
          </div>
        </div>
      </div>
    </header>
  );
};

export default TopNavBar;
