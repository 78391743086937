import { useEffect, useState } from "react";
import config from "../../../../app/config";
import moment from "moment";
import { Link } from "react-router-dom";
import { tokenFormat } from "../../../../app/utilities/format";

const ClaimBids = ({claimBids}) => {
    //const [claimBids, setClaimBids] = useState([]);

    // useEffect(() => {
    //   if (!nameBids) return;console.log('claimBids render')
  
    //   let rows = nameBids.rows;
    //   rows = rows.filter((row) => row.high_bid < 0);
    //   rows.sort((rowA, rowB) => moment(rowB.last_bid_time).unix() - moment(rowA.last_bid_time).unix());
  
    //   setClaimBids(rows);
    // }, [nameBids]);

  return (<>
   <table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Bidder</th>
            <th scope="col">Amount (WAX)</th>
            <th scope="col">Bid Time</th>
          </tr>
        </thead>
        <tbody>
          {claimBids.map((claimBid, index) => (
            <tr key={index}>
              <td>
                <Link to={`/account/${claimBid.newname}`}>
                  {claimBid.newname}
                </Link>
              </td>
              <td>
                <Link to={`/account/${claimBid.high_bidder}`}>
                  {claimBid.high_bidder}
                </Link>
              </td>
              <td>
                {" "}
                {tokenFormat(
                  Math.abs(claimBid.high_bid) / Math.pow(10, config.token_precision)
                )}
              </td>
              <td> {moment(claimBid.last_bid_time).format("MMM DD YYYY, HH:mm:ss")}</td>
            </tr>
          ))}
        </tbody>
      </table>
  </>);
};

export default ClaimBids;
