import { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import PageTransition from "../../../../app/animations/page-transition";
import WalletTransactionAlert from "../../../../app/components/wallet-transaction-alert";
import config from "../../../../app/config";
import { WalletContext } from "../../../../app/contexts/wallet-context";
import ApiManager from "../../../../app/services/apiManager";
//import proxiesData from "../../../../dummy-data/proxies.json";

const Proxies = () => {
  const wallet = useContext(WalletContext);
  const { user } = wallet;
  //const [proxies, setProxies] = useState(proxiesData);
  const [voteProxy, setVoteProxy] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);

  const {
    //  isLoading,
    // error,
    data: proxies,
    refetch,
  } = useQuery([`proxies`], () => ApiManager.getProxies(), { enabled: false });

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      refetch();
    }, 500);
    
    return () => clearTimeout(timeoutId);
  }, []);

  const handleClick = () => {
    var walletTransaction = {};
    walletTransaction.type = "voteproducer";

    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio",
      name: "voteproducer",
      data: {
        voter: user ? user.actor : "",
        proxy: voteProxy,
        producers: [],
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  const itemsPerPage = 100;
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    if(!proxies) return;
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(proxies.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(proxies.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, proxies]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % proxies.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Helmet>
        <title>Vote Proxies | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="container border p-2">
          <button
            type="button"
            className="btn btn-primary btn-sm  m-2"
            onClick={() => handleClick()}
          >
            Proxy to {voteProxy}
          </button>
          <WalletTransactionAlert />
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Select</th>
                <th scope="col">Rank</th>
                <th scope="col">Account</th>
                <th scope="col">Total WAX</th>
                <th scope="col">Proxied Accounts</th>
                <th scope="col">Voting For</th>
              </tr>
            </thead>
            <tbody>
              {!proxies &&
                Array(5)
                  .fill(0)
                  .map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
              {
                currentItems.map((proxy, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="radio"
                        name="voterProxy"
                        checked={voteProxy === proxy.account ? true : false}
                        onClick={(event) => {
                          setVoteProxy(proxy.account);
                        }}
                      />
                    </td>
                    <td>{proxy.rank}</td>
                    <td>
                      <img
                        src={`${
                          proxy.logo_256
                            ? `${config.imagekit.default_url_endpoint}/${proxy.logo_256}`
                            : "/no-image.png"
                        }`}
                        alt=""
                        width={30}
                        height={30}
                      />{" "}
                      <Link to={`/account/${proxy.account}`}>
                        {proxy.account}
                      </Link>
                    </td>
                    <td>{proxy.proxied_eos.toLocaleString()}</td>
                    <td>{proxy.vote_count.toLocaleString()}</td>
                    <td>{proxy.producer_count.toLocaleString()}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <ReactPaginate
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        className={"pagination"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        activeClassName={"active"}
      /> 
        </div>
      </PageTransition>
    </>
  );
};

export default Proxies;
