import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

const Chart1 = () => {
  const [options, setOptions] = useState({
          
    series: [76],
    options: {
      
      chart: {
        type: 'radialBar',
        offsetY: -20,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#e7e7e7",
            strokeWidth: '97%',
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: '#999',
              opacity: 1,
              blur: 2
            }
          },
          dataLabels: {
            name: {
              show: false
            },
            value: {
              offsetY: -2,
              fontSize: '22px'
            }
          }
        }
      },
      // grid: {
      //   padding: {
      //     //top: -10
      //   }
      // },
       fill: {
        type: 'gradient',
        gradient: {
          shade: 'light',
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91]
        },
      },
      labels: ['Average Results'],
    },
  
  
  });
  return (
    <>
    <div className="card card-body">
        <div className="row">
          <div className="col-md-6">
          <span className="d-block fw-bold">RAM</span>
            <span className="d-block text-muted">12,345</span>
            <span className="d-block text-muted">+12.5%</span>
          </div>
          <div className="col-md-6">
          <ReactApexChart
        options={options.options}
        series={options.series}
        type="radialBar"
        height={150}
      />
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Chart1;
