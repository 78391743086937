import React, { createContext, useCallback, useEffect, useState } from "react";
import BlockchainApiManager from "../services/blockchainApiManager";
import WalletService from "../services/wallet";
import AnchorLinkService from "../services/wallet/anchor-link";
import WaxcloudService from "../services/wallet/waxcloud";

const WalletContext = createContext();
const initialBalances = [
  {
    contract: "eosio.token",
    decimals: "8",
    currency: "WAX",
    amount: "0.00000000",
  },
];
const WalletContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [walletAccount, setWalletAccount] = useState({
    account: null,
    userres: null,
    delband: null,
    balances: initialBalances,
  });
  const [transactionAlert, setTransactionAlert] = useState(null);

  useEffect(() => {
    if (!user) {
      setWalletAccount({
        ...walletAccount,
        account: null,
        userres: null,
        delband: null,
        balances: initialBalances,
      });
    } else {
      BlockchainApiManager.getAccount(user.actor).then((account) => {
        setWalletAccount((walletAccount) => ({
          ...walletAccount,
          account: account,
        }));
      });

      BlockchainApiManager.getTableRows({
        code: "eosio",
        scope: user.actor,
        table: "delband",
        limit: -1,
      }).then((response) => {
        setWalletAccount((walletAccount) => ({
          ...walletAccount,
          delband: response,
        }));
      });

      BlockchainApiManager.getTableRows({
        code: "eosio",
        scope: user.actor,
        table: "userres",
        limit: -1,
      }).then((response) => {
        setWalletAccount((walletAccount) => ({
          ...walletAccount,
          userres: response,
        }));
      });
    

      BlockchainApiManager.getBalances(user.actor).then((response) => {
        const balances = response.balances || null;
        setWalletAccount((walletAccount) => ({
          ...walletAccount,
          balances: balances,
        }));
      });
    }

    //setAccount(account)
  }, [user]);

  const login = (data) => {
    var userData = { actor: "", permission: "", provider: "" };
    userData = Object.assign(userData, data);

    setUser(userData);
    window.$("#walletConnectModal").modal("hide");
  };

  const logout = () => {
    setUser(null);
  };

  const connectWallet = useCallback(async (provider) => {
    WalletService.initProvider(provider);
    switch (provider) {
      case WaxcloudService.provider:
        var waxcloudConnected = await WalletService.serviceProvider.connect();
        if (waxcloudConnected !== true) {
          console.log("Not connected");
          return;
        }

        var waxcloudUserAccount = await WalletService.serviceProvider.login();
        if (!waxcloudUserAccount) {
          console.log("Not logged in");
          return;
        }
        //console.log(waxcloudUserAccount);
        wallet.login({
          actor: waxcloudUserAccount,
          permission: "active",
          provider: provider,
        });
        break;
      case AnchorLinkService.provider:
        var anchorLinkConnected = await WalletService.serviceProvider.connect();
        if (anchorLinkConnected !== true) {
          console.log("Not connected");
          return;
        }

        var identity = await WalletService.serviceProvider.login();
        var anchorLinkSession = identity.session;

        if (!anchorLinkSession) {
          console.log("Not loggedin");
          return;
        }

        //console.log(anchorLinkSession);
        wallet.login({
          actor: waxcloudUserAccount,
          permission: "active",
          provider: provider,
        });
        break;
      default:
        break;
    }
  }, []);

  const submitTransaction = useCallback(
    async (walletTransaction) => {
      if (!user) {
        window.$("#walletConnectModal").modal("show");
        return;
      }
      
      const { provider } = user;

      WalletService.initProvider(provider);
      switch (provider) {
        case "waxcloud":
          var waxcloudConnected = await WalletService.serviceProvider.connect();
          if (waxcloudConnected !== true) {
            console.log("Not connected");
            return;
          }

          var waxcloudUserAccount = await WalletService.serviceProvider.login();
          if (!waxcloudUserAccount) {
            console.log("Not logged in");
            return;
          }

          walletTransaction.account = {
            account: waxcloudUserAccount,
            permission: "active",
          };

          WalletService.serviceProvider
            .transaction(walletTransaction)
            .then(function (tx) {
              console.log(tx);
              transactionSuccess(tx);
            })
            .catch(function (error) {
              console.log("error", error);
              transactionError(error);
            });

          break;
        case "anchor-link":
          var anchorLinkConnected =
            await WalletService.serviceProvider.connect();
          if (anchorLinkConnected !== true) {
            console.log("Not connected");
            return;
          }

          var anchorLinkSession =
            await WalletService.serviceProvider.restoreSession();
          if (!anchorLinkSession) {
            var identity = await WalletService.serviceProvider.login();
            anchorLinkSession = identity.session;
          }

          if (!anchorLinkSession) {
            console.log("Not loggedin");
            return;
          }

          walletTransaction.account = {
            account: String(anchorLinkSession.auth.actor),
            permission: String(anchorLinkSession.auth.permission),
          };

          WalletService.serviceProvider
            .transaction(walletTransaction)
            .then(function (tx) {
              console.log(tx);
              transactionSuccess(tx);
            })
            .catch(function (error) {
              console.log("error", error);
              transactionError(error);
            });

          break;
        default:
          break;
      }
    },
    [user]
  );

  const transactionSuccess = (tx) => {
    let message = `<a href="/transaction/${tx.transaction_id}" target="_blank">Transaction success - ${tx.transaction_id}</a>`;

    setTransactionAlert({ type: "success", message: message });
  };

  const transactionError = (error) => {
    let message = "";
    try {
      error = JSON.parse(error);
    } catch (err) {}

    if (error.code === 500) {
      message = "Transaction failed ";
      message += "<br/>" + error.code + ": " + error.message;
      if (error.error.details[0]) {
        message += "<br/>" + error.error.details[0].message;
      }
    } else {
      message = "Transaction failed - " + error.message;
    }

    setTransactionAlert({ type: "error", message: message });
  };

  const wallet = {
    user: user,
    walletAccount: walletAccount,
    login: login,
    logout: logout,
    connectWallet: connectWallet,
    submitTransaction: submitTransaction,
    transactionAlert: transactionAlert,
    setTransactionAlert: setTransactionAlert,
    multisig: { mode: false, walletTransaction: null },
  };

  return (
    <WalletContext.Provider value={wallet}>{children}</WalletContext.Provider>
  );
};

export { WalletContext, WalletContextProvider };
