import WaxcloudService from "./waxcloud";
import AnchorLinkService from "./anchor-link";
// import { useContext } from "react";
// import { WalletContext } from "../../contexts/wallet-context";


const WalletService = {
  serviceProvider: null,
  walletTransaction: null,
  initProvider(provider) {
    switch (provider) {
      case WaxcloudService.provider:
        WaxcloudService.init("https://wax.greymass.com");
        this.serviceProvider = WaxcloudService;
        break;
      case AnchorLinkService.provider:
        AnchorLinkService.init("https://wax.greymass.com");
        this.serviceProvider = AnchorLinkService;
        break;
      default:
        break;
    }
  } 
};

export default WalletService;
