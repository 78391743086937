import React from "react";
//import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { WalletContextProvider } from "./app/contexts/wallet-context";
// import { SocketContextProvider } from "./app/contexts/socket-context";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({}),
});

const container = document.getElementById("root");

const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <SkeletonTheme color="#424e5a" highlightColor="#4b5966">
      <WalletContextProvider>
      {/* <SocketContextProvider> */}
        <BrowserRouter>
          <App />
        </BrowserRouter>
        {/* </SocketContextProvider> */}
      </WalletContextProvider>
    </SkeletonTheme>
  </QueryClientProvider>
  // </React.StrictMode>
);

// ReactDOM.render(
// <React.StrictMode>
//     <QueryClientProvider client={queryClient}>
//       <SkeletonTheme color="#424e5a" highlightColor="#4b5966">
//         <WalletContextProvider>
//           <App />
//         </WalletContextProvider>
//       </SkeletonTheme>
//     </QueryClientProvider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
