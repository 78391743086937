import config from "../config";

const tokenFormat = (token, precision) => {
  precision = typeof precision === 'undefined'?config.token_precision:precision;

  token = Number(token);
  token = token.toFixed(precision);

  token = Number(token).toLocaleString(undefined, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  return token;
};

const currencyFormat = (value, precision) => {
  precision = typeof precision === 'undefined'?2:precision;

  value = Number(value);
  value = value.toFixed(precision);
 
  value = Number(value).toLocaleString(undefined, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });

  return value;
};

const bytesFormat = (bytes, precision) => {
  if (bytes < 0) return "∞ Bytes";

  if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return "-";
  if (typeof precision === "undefined") precision = 1;
  var units = ["Bytes", "KB", "MB", "GB", "TB", "PB"],
    number = Math.floor(Math.log(bytes) / Math.log(1024));
  if (String(number).toLowerCase() === "-infinity") {
    number = 0;
  }
  return (
    (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) +
    " " +
    units[number]
  );
};

const microsecondsFormat = (microseconds, precision) => {
  if (microseconds < 0) return "∞ ms";

  var hours = (microseconds / (60 * 60 * 1000 * 1000)).toFixed(precision);
  var minutes = (microseconds / (60 * 1000 * 1000)).toFixed(precision);
  var seconds = (microseconds / (1000 * 1000)).toFixed(precision);
  var milliseconds = (microseconds / 1000).toFixed(precision);

  if (hours >= 1) {
    return hours + " " + "h";
  } else if (minutes >= 1) {
    return minutes + " " + "m";
  } else if (seconds >= 1) {
    return seconds + " " + "s";
  } else if (milliseconds > 0) {
    return milliseconds + " " + "ms";
  } else {
    return milliseconds + " " + "ms";
  }
};

export { tokenFormat, currencyFormat, bytesFormat, microsecondsFormat };
