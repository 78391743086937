// import React from "react";
// import Transaction from "./transaction";
// import TransactionSkeleton from "./transaction-skeleton";

// const TransactionPage = () => {
//   return (
//     <>
//       <React.Suspense fallback={<TransactionSkeleton/>}>
//         <Transaction />
//       </React.Suspense>
//     </>
//   );
// };

// export default TransactionPage;

import Transaction from "./transaction";

export default Transaction;
