import React, { useState, useDeferredValue, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BlockchainApiManager from "../../app/services/blockchainApiManager";

const SearchList = ({ items, setKeywords, keywords }) => {
  const deferredItems = useDeferredValue(items, { timeoutMs: 5000 });

  const intialItems = [
    { scope: "eosio" },
    { scope: "eosio.token" },
    { scope: "eosio.vpay" },
  ];

  return (
    <>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuSearch">
        {keywords === "" && deferredItems.length === 0 ? (
          // <li>
          //   <a className="dropdown-item">No records</a>
          // </li>
          <>
            {intialItems.map((item, index) => (
              <li key={index}>
                <Link
                  className="dropdown-item"
                  to={`/account/${item.scope}`}
                  onClick={() => setKeywords(item.scope)}
                >
                  {item.scope}
                </Link>
              </li>
            ))}
          </>
        ) : null}
        {deferredItems.map((item, index) => (
          <li key={index}>
            <Link
              className="dropdown-item"
              to={`/account/${item.scope}`}
              onClick={() => setKeywords(item.scope)}
            >
              {item.scope}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

const Search = () => {
  let [keywords, setKeywords] = useState("");
  const [items, setItems] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    if (keywords === "") return;

    keywords = keywords.toLowerCase();
    BlockchainApiManager.getTableByScope({
      code: "eosio",
      table: "userres",
      lower_bound: keywords,
      upper_bound: keywords.padEnd(12, "z"),
      limit: 10,
    }).then((res) => {
      setItems(res.rows);
    });

    // if (new RegExp("^[0-9]*$").test(keywords)) {
    //   //Block
    //   navigate(`block/${keywords}`);
    // } else if (new RegExp("^([a-z0-9]){60,}$", "i").test(keywords)) {
    //   //Txid
    //   navigate(`transaction/${keywords}`);
    // } else if (new RegExp("^[a-z1-5.]*$", "i").test(keywords)) {
    //Account
    // BlockchainApiManager.getTableByScope({
    //   code: "eosio",
    //   table: "userres",
    //   lower_bound: keywords,
    //   upper_bound: keywords.padEnd(12, "z"),
    //   limit: 10,
    // }).then((res) => {
    //   setItems(res.rows);
    // });
    // } else {
    //   setItems([
    //     { scope: "eosio" },
    //     { scope: "eosio.token" },
    //     { scope: "eosio.vpay" },
    //     { scope: "eosio.msig" },
    //   ]);
    // }
    // if (keywords) {
    //   BlockchainApiManager.getTableByScope({
    //     code: "eosio",
    //     table: "userres",
    //     lower_bound: keywords,
    //     upper_bound: keywords.padEnd(12, "z"),
    //     limit: 10,
    //   }).then((res) => {
    //     setItems(res.rows);
    //   });
    // } else {
    //   setItems([
    //     { scope: "b1" },
    //     { scope: "eosio" },
    //     { scope: "eosio.token" },
    //     { scope: "eosio.vpay" },
    //     { scope: "eosio.msig" },
    //   ]);
    // }
  }, [keywords]);

  function handleChange(e) {
    setKeywords(e.target.value);
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if (new RegExp("^[0-9]*$").test(keywords)) {
        //Block
        navigate(`block/${keywords}`);
      } else if (new RegExp("^([a-z0-9]){60,}$", "i").test(keywords)) {
        //Txid
        navigate(`transaction/${keywords}`);
      } else if (new RegExp("^[a-z1-5.]*$", "i").test(keywords)) {
        //Account
        BlockchainApiManager.getTableByScope({
          code: "eosio",
          table: "userres",
          lower_bound: keywords,
          upper_bound: keywords.padEnd(12, "z"),
          limit: 10,
        }).then((res) => {
          setItems(res.rows);
        });
      } else {
        setItems([
          { scope: "eosio" },
          { scope: "eosio.token" },
          { scope: "eosio.vpay" },
          { scope: "eosio.msig" },
        ]);
      }
    }
  };

  return (
    <>
      <form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
        <input
          type="text"
          className="form-control form-control-dark dropdown-toggle"
          placeholder="Search..."
          aria-label="Search"
          value={keywords}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          id="dropdownMenuSearch"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          autoComplete="off"
        />
        <SearchList
          items={items}
          setKeywords={setKeywords}
          keywords={keywords}
        />
      </form>
    </>
  );
};

export default Search;
