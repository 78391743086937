import { getType } from "eosjs/dist/eosjs-serialize";

import Skeleton from "react-loading-skeleton";
import { useQuery, useInfiniteQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import BlockchainApiManager from "../../../app/services/blockchainApiManager";
import moment from "moment";
import PageTransition from "../../../app/animations/page-transition";
import { getShortTrxId } from "../../../app/utilities/transaction";
import React from "react";
import DisplayActionAccountName from "../../../app/components/blockchain/action/display-action-account-name";
import DisplayActionData from "../../../app/components/blockchain/action/display-action-data";

const Transactions = () => {
  let params = useParams();

  const {
    isLoading,
    error,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    data: actions,
  } = useInfiniteQuery(
    [`actions`, params.account],
    async ({ pageParam = -1 }) =>
      BlockchainApiManager.getActions({
        account_name: params.account,
        pos: pageParam,
        offset: pageParam === -1 ? -100 : -99,
      }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.actions[0]) {
          return lastPage.actions[0].account_action_seq - 1;
        }

        return false;
      },
    }
  );
  console.log(actions);
  return (
    <>
      <div className="container border p-2">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col" style={{ width: "100px" }}>TX ID</th>
                <th scope="col" style={{ width: "150px" }}>Date</th>
                <th scope="col" style={{ width: "150px" }}>Action</th>
                <th scope="col">Data</th>
              </tr>
            </thead>
            <tbody>
              {!actions &&
                Array(5)
                  .fill(0)
                  .map((item, index) => (
                    <tr key={index}>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                      <td>
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
              {actions &&
                actions.pages.map((page) => (
                  <React.Fragment key={page.nextId}>
                    {/* {actions &&
                actions.actions.map((action, index) => ( */}
                    {page.actions.map((action, index) => (
                      <tr key={index}>
                        <td>
                          <Link
                            to={`/transaction/${action.action_trace.trx_id}`}
                          >
                            {getShortTrxId(action.action_trace.trx_id)}
                          </Link>
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          {moment(action.action_trace.block_time).format("MMM DD YYYY, HH:mm:ss")}
                        </td>
                        <td style={{ whiteSpace: "nowrap" }}>
                          <div
                            className="btn-group d-block pb-2"
                            role="group"
                            aria-label="Basic example"
                          >
                            <Link
                              to={`/account/${action.action_trace.act.account}`}
                              className="btn btn-primary  btn-sm"
                            >
                              {action.action_trace.act.account}
                            </Link>
                            <button
                              type="button"
                              className="btn btn-primary  btn-sm"
                            >
                              {action.action_trace.act.name}
                            </button>
                          </div>
                        </td>
                        <td>
                          <DisplayActionData
                            action={action.action_trace.act}
                            account_name={params.account}
                          />
                           {/* <br/>
                          {JSON.stringify(action.action_trace.act.data)} */}
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </div>

        <div className="col-md-12 text-center mt-2">
          <button
            className="btn btn-primary"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage
              ? "Loading more..."
              : hasNextPage
              ? "Load More"
              : "Nothing more to load"}
          </button>
        </div>
      </div>
    </>
  );
};

export default Transactions;
