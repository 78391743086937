import { Link, useParams } from "react-router-dom";
import BlockchainApiManager from "../app/services/blockchainApiManager";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import { JSONTree } from "react-json-tree";
import { motion } from "framer-motion";
import moment from "moment";
import { useEffect, useState } from "react";
import PageTransition from "../app/animations/page-transition";

const Block = () => {
  const [timeLeft, setTimeLeft] = useState(null);
  let params = useParams();

  useEffect(() => {
    console.log("Block render");
  }, []);

  const {
    isLoading,
    error,
    data: block,
  } = useQuery(
    [`block`, params.id],
    () => BlockchainApiManager.getBlock(params.id),
    { suspense: false }
  );

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    if (timeLeft === 0) {
      console.log("TIME LEFT IS 0");
      setTimeLeft(null);
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  useEffect(() => {
    if (!block) return;

    setTimeLeft(
      180 - moment().diff(moment(block.timestamp + "Z"), "seconds")
    );
  }, [block]);

  const getTotalCpuUsage = () => {
    var totalCpuUsage = block.transactions
      .filter(function (transaction) {
        return transaction.cpu_usage_us;
      })
      .reduce(function (totalCpuUsage, transaction) {
        return totalCpuUsage + transaction.cpu_usage_us;
      }, 0);

    return totalCpuUsage;
  };

  const getTotalNetUsage = () => {
    var totalNetUsage = block.transactions
      .filter(function (transaction) {
        return transaction.net_usage_words;
      })
      .reduce(function (totalNetUsage, transaction) {
        return totalNetUsage + transaction.net_usage_words;
      }, 0);

    return totalNetUsage;
  };

  const getTotalActions = () => {
    var totalActions = block.transactions
      .filter(function (transaction) {
        return transaction.trx.transaction
          ? transaction.trx.transaction.actions.length
          : 0;
      })
      .reduce(function (totalActions, transaction) {
        return (
          totalActions +
          (transaction.trx.transaction
            ? transaction.trx.transaction.actions.length
            : 0)
        );
      }, 0);

    return totalActions;
  };

  if (error) {
    return (
      <>
        <div className="card card-body p-3">
          <h5 className="card-title">Block Not Found</h5>
          <p>Cannot find Block {params.id.toLocaleString()}</p>
        </div>
      </>
    );
  }

  return (
    <>
     <Helmet>
        <title>{params.id} | Block - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="card card-body p-3">
          <h5 className="card-title">
            Block #
            {block ? (
              <motion.span
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                {block.block_num.toLocaleString()}
              </motion.span>
            ) : (
              <Skeleton style={{ width: "80%" }} />
            )}
            {/* {(block && block.block_num) || (
            <Skeleton style={{ width: "600px" }} />
          )} */}
          </h5>
          <div className="row p-3">
            <div className="col-md-6 col-sm-12">
              <h6>Summary</h6>
              <table className="table table-borderless">
                <tr>
                  <td className="w-50">Block Number:</td>
                  <td>{(block && block.block_num.toLocaleString()) || <Skeleton />}</td>
                </tr>
                <tr>
                  <td className="w-50">Timestamp:</td>
                  <td>{(block && moment(block.timestamp).format("MMM DD YYYY, HH:mm:ss")) || <Skeleton />}</td>
                </tr>
                <tr>
                  <td className="w-50">Producer Name:</td>
                  <td>
                    {block ? (
                      <Link to={`/account/${block.producer}`}>
                        {block.producer}
                      </Link>
                    ) : (
                      <Skeleton />
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Status:</td>
                  <td>
                    {block ? (
                      moment().diff(moment(block.timestamp + "Z"), "seconds") <=
                      180 ? (
                        <span className="badge bg-warning">
                          Pending Irreversibility ({timeLeft}s)
                        </span>
                      ) : (
                        <span className="badge bg-success">Irreversible</span>
                      )
                    ) : (
                      <Skeleton />
                    )}
                  </td>
                </tr>
                {/* <tr>
                <td >Block ID:</td>
                <td style={{ lineBreak: "anywhere" }}>
                  {(block && block.id) || (
                    <Skeleton style={{ width: "200px" }} />
                  )}
                </td>
              </tr> */}
              </table>
            </div>
            <div className="col-md-6 col-sm-12">
              <h6>Additional</h6>
              <table>
                <tr>
                  <td className="w-50">Resources Used - CPU/NET:</td>
                  <td>
                    {block ? (
                      <>
                        {getTotalCpuUsage()} μs / {getTotalNetUsage() * 8} bytes
                      </>
                    ) : (
                      <Skeleton />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="w-50">Number of TX</td>
                  <td>
                    {(block && block.transactions.length) || <Skeleton />}
                  </td>
                </tr>
                <tr>
                  <td className="w-50">Number of Actions: </td>
                  <td>{block ? getTotalActions() : <Skeleton />}</td>
                </tr>
                <tr>
                  <td className="w-50">Previous Block: </td>
                  <td>
                    {block ? (
                      <Link to={`/block/${block.block_num - 1}`}>
                        {(block.block_num - 1).toLocaleString()}
                      </Link>
                    ) : (
                      <Skeleton />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="w-50">Next Block: </td>
                  <td>
                    {block ? (
                      <Link to={`/block/${block.block_num + 1}`}>
                        {(block.block_num + 1).toLocaleString()}
                      </Link>
                    ) : (
                      <Skeleton />
                    )}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="card card-body p-3 mt-3">
          <h5 className="card-title">Block information</h5>
          <ul className="nav nav-tabs mt-2" id="blockTab">
            <li className="nav-item">
              <button
                className="nav-link active"
                id="transactions-tab"
                data-bs-toggle="tab"
                data-bs-target="#transactions"
                type="button"
                role="tab"
                aria-controls="transactions"
                aria-selected="true"
              >
                Transactions
              </button>
            </li>
            <li className="nav-item">
              <button
                className="nav-link"
                id="raw-tab"
                data-bs-toggle="tab"
                data-bs-target="#raw"
                type="button"
                role="tab"
                aria-controls="raw"
                aria-selected="true"
              >
                Raw
              </button>
            </li>
          </ul>
          <div className="tab-content" id="blockTabContent">
            <div
              className="tab-pane fade show active"
              id="transactions"
              role="tabpanel"
              aria-labelledby="transactions-tab"
            >
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">TX ID</th>
                      <th scope="col">Expiration</th>
                      <th scope="col">CPU Usage</th>
                      <th scope="col">NET Usage</th>
                      <th scope="col">Number of Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!block &&
                      Array(5)
                        .fill(0)
                        .map((item, index) => (
                          <tr key={index}>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                        ))}
                    {block &&
                      block.transactions.map((transaction, index) => (
                        <tr key={index}>
                          <td>
                            <Link
                              to={`/transaction/${
                                transaction.trx.id
                                  ? transaction.trx.id
                                  : transaction.trx
                              }`}
                            >
                              {transaction.trx.id
                                ? transaction.trx.id
                                : transaction.trx}
                            </Link>
                          </td>
                          <td>
                            {transaction.trx.transaction
                              ? transaction.trx.transaction.expiration
                              : "N/A"}
                          </td>
                          <td>
                            {transaction.cpu_usage_us || 0}
                            μs
                          </td>
                          <td>
                            {(transaction.net_usage_words || 0) * 8}
                            Bytes
                          </td>
                          <td>
                            {transaction.trx.transaction
                              ? transaction.trx.transaction.actions.length
                              : "0"}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="tab-pane"
              id="raw"
              role="tabpanel"
              aria-labelledby="raw-tab"
            >
              <JSONTree data={block} />
            </div>
          </div>
        </div>
      </PageTransition>
    </>
  );

  return (
    <>
      {block ? (
        <>
          <Helmet>
            <title>{`${block.block_num}`} | WAX Block</title>
          </Helmet>
          <div className="card card-body p-3">
            <h5 className="card-title">Block #{block.block_num}</h5>
            <div className="row p-3">
              <div className="col-6">
                <h6>Summary</h6>
                <table>
                  <tr>
                    <td>Block Number:</td>
                    <td>{block.block_num}</td>
                  </tr>
                  <tr>
                    <td>Timestamp:</td>
                    <td>{block.timestamp}</td>
                  </tr>
                  <tr>
                    <td>Producer Name:</td>
                    <td>{block.producer}</td>
                  </tr>
                  <tr>
                    <td>Block ID:</td>
                    <td style={{ lineBreak: "anywhere" }}>{block.id}</td>
                  </tr>
                </table>
              </div>
              <div className="col-6">
                <h6>Additional</h6>
                <table>
                  <tr>
                    <td>Resources Used - CPU/NET:</td>
                    <td>
                      {getTotalCpuUsage()} μs / {getTotalNetUsage() * 8} bytes
                    </td>
                  </tr>
                  <tr>
                    <td>Number of TX</td>
                    <td>{block.transactions.length}</td>
                  </tr>
                  <tr>
                    <td>Number of Actions: </td>
                    <td>{getTotalActions()}</td>
                  </tr>
                  <tr>
                    <td>Previous Block: </td>
                    <td>{block.block_num - 1}</td>
                  </tr>
                  <tr>
                    <td>Next Block: </td>
                    <td>{block.block_num + 1}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="card card-body p-3 mt-3">
            <h5 className="card-title">Block information</h5>
            <ul className="nav nav-tabs mt-2" id="blockTab">
              <li className="nav-item">
                <button
                  className="nav-link active"
                  id="transactions-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#transactions"
                  type="button"
                  role="tab"
                  aria-controls="transactions"
                  aria-selected="true"
                >
                  Transactions
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="nav-link"
                  id="raw-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#raw"
                  type="button"
                  role="tab"
                  aria-controls="raw"
                  aria-selected="true"
                >
                  Raw
                </button>
              </li>
            </ul>
            <div className="tab-content" id="blockTabContent">
              <div
                className="tab-pane fade show active"
                id="transactions"
                role="tabpanel"
                aria-labelledby="transactions-tab"
              >
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">TX ID</th>
                      <th scope="col">Expiration</th>
                      <th scope="col">CPU Usage</th>
                      <th scope="col">NET Usage</th>
                      <th scope="col">Number of Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {block.transactions.map((transaction, index) => (
                      <tr key={index}>
                        <td>
                          <Link
                            to={`/transaction/${
                              transaction.trx.id
                                ? transaction.trx.id
                                : transaction.trx
                            }`}
                          >
                            {transaction.trx.id
                              ? transaction.trx.id
                              : transaction.trx}
                          </Link>
                        </td>
                        <td>
                          {transaction.trx.transaction
                            ? transaction.trx.transaction.expiration
                            : "N/A"}
                        </td>
                        <td>
                          {transaction.cpu_usage_us || 0}
                          μs
                        </td>
                        <td>
                          {(transaction.net_usage_words || 0) * 8}
                          Bytes
                        </td>
                        <td>
                          {transaction.trx.transaction
                            ? transaction.trx.transaction.actions.length
                            : "0"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div
                className="tab-pane"
                id="raw"
                role="tabpanel"
                aria-labelledby="raw-tab"
              >
                <JSONTree data={block} />
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Block;
