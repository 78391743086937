import { useContext } from "react";
import { WalletContext } from "../../../app/contexts/wallet-context";
import { calculateBreakDown } from "../../../app/utilities/account";
import { bytesFormat, microsecondsFormat, tokenFormat } from "../../../app/utilities/format";

const AccountSummary = () => {
  const wallet = useContext(WalletContext);
  const { account, delband, userres } = wallet.walletAccount;

  if (!account) return;

  const breakdown = calculateBreakDown({ account, delband, userres });

  return (
    <>
      <div className="card card-body mb-2">
        <h5 className="card-title">
          {" "}
          Total WAX balance: {breakdown.totalBalance.toLocaleString()} WAX ($
          {(breakdown.totalBalance * 0.292).toLocaleString()})
        </h5>
        <h5 className="card-title">
          {" "}
          Available WAX balance:{" "}
          {parseFloat(breakdown.liquidBalance).toLocaleString()} WAX
        </h5>
        <h5 className="card-title">CPU Staked:  {tokenFormat(breakdown.cpuStakedSelf+breakdown.cpuStakedToOthers)} WAX</h5>
        <h5 className="card-title">NET Staked:  {tokenFormat(breakdown.netStakedSelf+breakdown.netStakedToOthers)} WAX</h5>
        <h5 className="card-title">Staked by Others: {breakdown.stakedByOthers.toLocaleString()} WAX</h5>
        <h5 className="card-title">Refunding: {tokenFormat(breakdown.unstaking, 4)} WAX</h5>

        <div className="row p-2 border">
          <div className="col">
            <span className="d-block fw-bold">
              {((account.ram_usage / account.ram_quota) * 100).toFixed(0)}% RAM
              used
            </span>
            <span className="d-block fw-bold">
              {bytesFormat(account.ram_usage, 2)} /{" "}
              {bytesFormat(account.ram_quota, 2)}
            </span>

            {/* <span className="d-block fw-bold">{ bytesFormat(account.ram_quota - account.ram_usage, 2)}</span> */}
            {/* <span className="d-block text-muted">RAM</span> */}
          </div>
          <div className="col">
            <span className="d-block fw-bold">
              {((account.cpu_limit.used / account.cpu_limit.max) * 100).toFixed(
                0
              )}
              % CPU used
            </span>
            <span className="d-block fw-bold">
              {microsecondsFormat(account.cpu_limit.used, 2)} /{" "}
              {microsecondsFormat(account.cpu_limit.max, 2)}
            </span>
            {/* <span className="d-block fw-bold">{microsecondsFormat(account.cpu_limit.available, 2)}</span> */}
            {/* <span className="d-block text-muted">CPU</span> */}
          </div>
          <div className="col">
            <span className="d-block fw-bold">
              {((account.net_limit.used / account.net_limit.max) * 100).toFixed(
                0
              )}
              % NET used
            </span>
            <span className="d-block fw-bold">
              {bytesFormat(account.net_limit.used, 2)}/
              {bytesFormat(account.net_limit.max, 2)}
            </span>
            {/* <span className="d-block fw-bold">{bytesFormat(account.net_limit.available, 2)}</span> */}
            {/* <span className="d-block text-muted">NET</span> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default AccountSummary;
