import { useContext } from "react";
import Helmet from "react-helmet";
import moment from "moment";
import PageTransition from "../../../../app/animations/page-transition";
import { WalletContext } from "../../../../app/contexts/wallet-context";

const Refund = () => {
  const wallet = useContext(WalletContext);
  const { user, walletAccount } = wallet;
  const { account } = walletAccount;
  console.log(account);

  return (
    <>
      <Helmet>
        <title>Refund | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="container border p-2">
          {user ? (
            <>
              {account.refund_request ? (
                <>
                  <table>
                    <tbody>
                      <tr>
                        <td>Refund Request:</td>
                        <td>
                          {moment(account.refund_request.request_time).format(
                            "MMM DD YYYY, HH:mm:ss"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>CPU Amount:</td>
                        <td>{account.refund_request.cpu_amount}</td>
                      </tr>
                      <tr>
                        <td>NET Amount:</td>
                        <td>{account.refund_request.net_amount}</td>
                      </tr>
                      <tr>
                        <td>Expected Refund:</td>
                        <td>
                          {moment(account.refund_request.request_time)
                            .add(72, "hours")
                            .format("MMM DD YYYY, HH:mm:ss")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <button className="btn btn-primary" disabled>Refund</button>
                </>
              ) : (
                <p>No outstanding refund request for {user.actor}</p>
              )}
            </>
          ) : (
            <>
              <p>Please login to process manual refund.</p>
            </>
          )}
        </div>
      </PageTransition>
    </>
  );
};

export default Refund;
