import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

const Chart1 = () => {
  const [options, setOptions] = useState({
    series: [70],
    options: {
      
      chart: {
        height: 150,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: ["Cricket"],
    },
  });
  return (
    <>
      <div className="card card-body">
        <div className="row">
          <div className="col-md-6">
            <span className="d-block fw-bold">Unstaked WAXP</span>
            <span className="d-block text-muted">55,678</span>
            <span className="d-block text-muted">+10.5%</span>
          </div>
          <div className="col-md-6">
            <ReactApexChart
              options={options.options}
              series={options.series}
              type="radialBar"
              height={150}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Chart1;
