import { useContext, useEffect, useState } from "react";
import Helmet from "react-helmet";
import PageTransition from "../../../../app/animations/page-transition";
import WalletTransactionAlert from "../../../../app/components/wallet-transaction-alert";
import config from "../../../../app/config";
import { WalletContext } from "../../../../app/contexts/wallet-context";
import { tokenFormat } from "../../../../app/utilities/format";

const Sell = () => {
  const wallet = useContext(WalletContext);
  const { user } = wallet;

  const [formData, setFormData] = useState({
    account: "",
    bytes: 0,
  });

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      account: user ? user.actor : "",
    }));
  }, [user]);

  const calculateValue = () => {
    let value = 0;
    if (formData.bytes > 0) {
      value = tokenFormat(formData.bytes / 1325);
    }

    return value;
  };

  const handleClick = async () => {
    var walletTransaction = {};
    walletTransaction.type = "sellram";

    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio",
      name: "sellram",
      data: {
        account: formData.account,
        bytes: formData.bytes,
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  return (
    <>
      <Helmet>
        <title>Sell RAM | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="container border p-2">
          <form>
            <div className="mb-3">
              <label className="form-label">
                Amount of RAM to sell (in Bytes)
              </label>
              <input
                type="number"
                className="form-control"
                value={formData.bytes}
                onChange={(event) =>
                  setFormData({ ...formData, bytes: event.target.value })
                }
              />
              <p className="text-muted">
                Selling {formData.bytes} Bytes for {calculateValue()}{" "}
                {config.token_symbol}
              </p>
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleClick()}
            >
              {formData.quantity > 0
                ? `Sell ${formData.bytes} bytes of RAM`
                : `Sell RAM`}
            </button>
          </form>
        </div>
        <WalletTransactionAlert />
      </PageTransition>
    </>
  );
};

export default Sell;
