import { Link, Outlet, useLocation } from "react-router-dom";
import PageTransition from "../../../app/animations/page-transition";

const Resources = () => {
  const location = useLocation();
  console.log(location);
  return (
    <>
      <PageTransition>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <Link
              className={`nav-link ${
                ["/wallet/resources", "/wallet/resources/stake"].includes(
                  location.pathname
                )
                  ? "active"
                  : ""
              }`}
              to="/wallet/resources/stake"
            >
              Stake
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                ["/wallet/resources/unstake"].includes(location.pathname)
                  ? "active"
                  : ""
              }`}
              to="/wallet/resources/unstake"
            >
              Unstake
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                ["/wallet/resources/refund"].includes(location.pathname)
                  ? "active"
                  : ""
              }`}
              to="/wallet/resources/refund"
            >
              Refund
            </Link>
          </li>
        </ul>
        <Outlet />
      </PageTransition>
    </>
  );
};

export default Resources;
