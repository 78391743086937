import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import BlockchainApiManager from "../../../../app/services/blockchainApiManager";
import { tokenFormat } from "../../../../app/utilities/format";

const TopHolders = () => {
  const {
    isLoading,
    error,
    data: topHolders,
  } = useQuery([`topHolders`], () => BlockchainApiManager.getTopHolders(), {});

  if (error) {
    return (
      <>
        <p>Error fetching</p>
      </>
    );
  }

  if (isLoading) {
    return (
      <>
        <p>Loading...</p>
      </>
    );
  }

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Rank</th>
            <th scope="col">Account</th>
            <th scope="col">Total WAX</th>
          </tr>
        </thead>
        <tbody>
          {topHolders.map((topHolder, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <Link to={`/account/${topHolder[0]}`}>{topHolder[0]}</Link>
              </td>
              <td> {tokenFormat(topHolder[1], 0)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default TopHolders;
