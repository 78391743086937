import { useContext, useEffect, useState } from "react";
import { JSONTree } from "react-json-tree";
import ReactJson from "react-json-view";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import PageTransition from "../../app/animations/page-transition";
import BlockchainApiManager from "../../app/services/blockchainApiManager";
import { Api, JsonRpc } from "eosjs";
import { JsSignatureProvider } from "eosjs/dist/eosjs-jssig";
import { hexToUint8Array } from "eosjs/dist/eosjs-serialize";
import config from "../../app/config";
import Skeleton from "react-loading-skeleton";
import { WalletContext } from "../../app/contexts/wallet-context";
import Helmet from "react-helmet";

const Msig = () => {
  const wallet = useContext(WalletContext);
  const { user } = wallet;
  const params = useParams();
  let navigate = useNavigate();
  const [approval, setApproval] = useState(null);
  const [transaction, setTransaction] = useState({});

  const { data: approvals } = useQuery(
    [`approvals`, params.scope, params.name],
    () =>
      BlockchainApiManager.getTableRows({
        code: "eosio.msig",
        scope: params.scope,
        table: "approvals",
        limit: -1,
      }),
    {}
  );

  const { data: approvals2 } = useQuery(
    [`approvals2`, params.scope, params.name],
    () =>
      BlockchainApiManager.getTableRows({
        code: "eosio.msig",
        scope: params.scope,
        table: "approvals2",
        limit: -1,
      }),
    {}
  );

  const { data: proposal } = useQuery(
    [`proposal`, params.scope, params.name],
    () =>
      BlockchainApiManager.getTableRows({
        code: "eosio.msig",
        scope: params.scope,
        table: "proposal",
        limit: 1,
        lower_bound: params.name,
        key_type: "name",
      }),
    {}
  );

  useEffect(() => {
    if (approvals2 && approvals2.rows) {
      approvals2.rows.forEach((row) => {
        if (row.proposal_name === params.name) {
          setApproval(row);
        }
      });
    }
  }, [approvals2]);

  useEffect(() => {
    if (proposal && proposal.rows[0]) {
      (async () => {
        const signatureProvider = new JsSignatureProvider([]);
        const rpc = new JsonRpc(config.endpoints.default); //required to read blockchain state
        const api = new Api({ rpc, signatureProvider }); //required to submit transactions

        var packed_trx = proposal.rows[0].packed_transaction;

        let transaction = await api.deserializeTransaction(
          hexToUint8Array(packed_trx)
        );

        transaction.actions = await api.deserializeActions(transaction.actions);

        setTransaction(transaction);
      })();
    }
  }, [proposal]);

  const approve = () => {
    var walletTransaction = {};
    walletTransaction.type = "approve";
    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio.msig",
      name: "approve",
      data: {
        proposer: params.scope,
        proposal_name: params.name,
        level: {
          actor: user ? user.actor : null,
          permission: user ? user.permission : "active",
        },
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  const execute = () => {
    var walletTransaction = {};
    walletTransaction.type = "execute";
    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: "eosio.msig",
      name: "exec",
      data: {
        proposer: params.scope,
        proposal_name: params.name,
        executer: user ? user.actor : "",
      },
    });

    wallet.submitTransaction(walletTransaction);
  };

  const copyAsMsig = () => {
    var walletTransaction = {};
    walletTransaction.type = "msig";
    walletTransaction.actions = [];

    if (
        transaction &&      
        transaction.actions
      ) {
        transaction.actions.forEach(function (action) {
          walletTransaction.actions.push(action);
        });
      }
  
      wallet.multisig.mode = true;
      wallet.multisig.walletTransaction = Object.assign({}, walletTransaction);
  
      navigate("/wallet/msig");
  }

  return (
    <>
    <Helmet>
        <title>MSIG | Block - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="row">
          <div className="col-md-12 " style={{ textAlign: "right" }}>
            <button
              type="button"
              className="btn btn-secondary mx-1"
              onClick={() => approve()}
            >
              Approve
            </button>
            <button
              type="button"
              className="btn btn-secondary mx-1"
              onClick={() => execute()}
            >
              Execute
            </button>
            <button type="button" className="btn btn-secondary mx-1"
            onClick={()=>copyAsMsig()}
            >
              Copy
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <span className="d-block fw-bold">{params.scope}</span>
            <span className="d-block text-muted">Proposer</span>
          </div>
          <div className="col-md-4">
            <span className="d-block fw-bold">{params.name}</span>
            <span className="d-block text-muted">Proposal name</span>
          </div>
          <div className="col-md-4">
            <span className="d-block fw-bold">
              {approval ? (
                <>
                  {approval.provided_approvals.length}/
                  {
                    [
                      ...approval.provided_approvals,
                      ...approval.requested_approvals,
                    ].length
                  }
                </>
              ) : (
                <Skeleton />
              )}
            </span>
            <span className="d-block text-muted">Approval status</span>
          </div>
        </div>

        <ul className="nav nav-tabs mt-2" id="msigTab">
          <li className="nav-item">
            <button
              className="nav-link active"
              id="requested_approvals-tab"
              data-bs-toggle="tab"
              data-bs-target="#requested_approvals"
              type="button"
              role="tab"
              aria-controls="requested_approvals"
              aria-selected="true"
            >
              Requested Approvals
            </button>
          </li>
          <li className="nav-item">
            <button
              className="nav-link"
              id="transaction-tab"
              data-bs-toggle="tab"
              data-bs-target="#transaction"
              type="button"
              role="tab"
              aria-controls="transaction"
              aria-selected="true"
            >
              Transaction
            </button>
          </li>
        </ul>
        <div className="tab-content" id="msigTabContent">
          <div
            className="tab-pane fade show active"
            id="requested_approvals"
            role="tabpanel"
            aria-labelledby="requested_approvals-tab"
          >
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Actor</th>
                    <th scope="col">Permission</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {approval &&
                    [
                      ...approval.provided_approvals,
                      ...approval.requested_approvals,
                    ].map((all_approval, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{all_approval.level.actor}</td>
                        <td>{all_approval.level.permission}</td>
                        <td>
                          {all_approval.time === "1970-01-01T00:00:00.000" ? (
                            <span className="badge bg-warning">Pending</span>
                          ) : (
                            <span className="badge bg-success">Approved</span>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>

          <div
            className="tab-pane fade show"
            id="transaction"
            role="tabpanel"
            aria-labelledby="transaction-tab"
          >
            <ReactJson src={transaction} />
          </div>
        </div>
      </PageTransition>
    </>
  );
};

export default Msig;
