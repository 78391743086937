import { Outlet } from "react-router-dom";

const ChartsDemo = () => {
  return (
    <div className="container">
      <h4>Demo</h4>
      <Outlet />
    </div>
  );
};

export default ChartsDemo;
