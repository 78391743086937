import { useState } from "react";
import BlockchainApiManager from "../../../../app/services/blockchainApiManager";

const RequestedApprovals = ({ formData, setFormData }) => {
  const [autocompleteRequestedActors, setAutocompleteRequestedActors] =
    useState([[]]);
  const [requestedPermissions, setRequestedPermissions] = useState([[]]);

  const addRequested = () => {
    formData.requested.push({ actor: "", permission: "" });
    autocompleteRequestedActors.push([]);
    requestedPermissions.push([]);
    setFormData({ ...formData });
  };

  const removeRequested = (index) => {
    formData.requested.splice(index, 1);
    autocompleteRequestedActors.splice(index, 1);
    requestedPermissions.splice(index, 1);
    setFormData({ ...formData });
  };

  const autocompleteRequestedActor = (index) => {
    if (
      !new RegExp("^[a-z1-5.]{1,12}$", "i").test(
        formData.requested[index].actor
      )
    ) {
      autocompleteRequestedActors[index] = [];
      setAutocompleteRequestedActors([...autocompleteRequestedActors]);
      return;
    }

    var keywords = formData.requested[index].actor;

    if (keywords === "") {
      keywords = "eosio";
    }

    BlockchainApiManager.getTableByScope({
      code: "eosio",
      table: "userres",
      lower_bound: keywords,
      upper_bound: keywords.padEnd(12, "z"),
      limit: 10,
    }).then((res) => {
      autocompleteRequestedActors[index] = [];
      if (res && res.rows) {
        res.rows.forEach((item, key) => {
          autocompleteRequestedActors[index].push({ scope: item.scope });
        });
      }

      console.log(autocompleteRequestedActors);
      setAutocompleteRequestedActors([...autocompleteRequestedActors]);
    });
  };

  const selectRequestedActor = (index, requestedActor) => {
    if (requestedActor) {
      formData.requested[index].actor = requestedActor.scope;
    }
    setFormData({ ...formData });

    requestedPermissions[index] = [];

    BlockchainApiManager.getAccount(formData.requested[index].actor).then(
      (res) => {
        autocompleteRequestedActors[index] = [];
        res.permissions.forEach((permission, key) => {
          requestedPermissions[index].push(permission);
        });

        setRequestedPermissions([...requestedPermissions]);
      }
    );
  };

  return (
    <>
      <div className="mb-3">
        <label className="form-label">Requested Approvals</label>

        {formData.requested.map((requested, index) => (
          <div className="row mb-2" key={index}>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Actor"
                value={requested.actor}
                onChange={(event) => {
                  formData.requested[index].actor = event.target.value;
                  setFormData({ ...formData });
                  autocompleteRequestedActor(index);
                }}
                id={`dropdownRequestedActorSearch${index}`}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                autoComplete="off"
              />
              <ul
                className={`dropdown-menu`}
                aria-labelledby={`dropdownRequestedActorSearch${index}`}
              >
                {autocompleteRequestedActors[index].map((item, key) => (
                  <li
                    key={key}
                    onClick={(e) => {
                      e.preventDefault();
                      selectRequestedActor(index, item);
                    }}
                    className="dropdown-item"
                  >
                    <a className="dropdown-item" href="">
                      {item.scope}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col">
              <select
                className="form-select"
                onChange={(event) => {
                  formData.requested[index].permission = event.target.value;
                  setFormData({ ...formData });
                }}
              >
                <option value="">--Select--</option>
                {requestedPermissions[index].map((permission, key) => (
                  <option key={key} value={permission.perm_name}>
                    {permission.perm_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col">
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={() => removeRequested(index)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}

        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={addRequested}
        >
          Add new
        </button>
      </div>
    </>
  );
};

export default RequestedApprovals;
