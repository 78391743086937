import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import highchartsSolidGauge from "highcharts/modules/solid-gauge";

import { useState } from "react";

highchartsMore(Highcharts);
highchartsSolidGauge(Highcharts);



const Chart5 = () => {
  const [options, setOptions] = useState(
     {
      chart: {
        type: "solidgauge",
        height: 100,
      },
    
      title: null,
    
      pane: {
        center: ["50%", "85%"],
        size: "100%",
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
          innerRadius: "90%",
          outerRadius: "100%",
          shape: "arc",
        },
      },
    
      exporting: {
        enabled: false,
      },
    
      tooltip: {
        enabled: false,
      },
      yAxis: {
        stops: [
            [0.1, '#55BF3B'], // green
            [0.5, '#DDDF0D'], // yellow
            [0.9, '#DF5353'] // red
        ],
        lineWidth: 0,
        minorTickInterval: null,
        tickPixelInterval: 400,
        tickWidth: 0,
        title: {
            y: -70
        },
        labels: {
            y: 16
        }
    },
    
    plotOptions: {
      solidgauge: {
          dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true
          }
      }
    },
      credits: {
        enabled: false,
      },

      series: [
        {
          name: "Speed",
          radius: 90,
          innerRadius: 100,
          data: [70],
        },
      ],
    })
  ;

  return (
    <>
      <div className="card card-body">
        <div className="row">
          <div className="col-md-6">
            <span className="d-block fw-bold">RAM</span>
            <span className="d-block text-muted">12,345</span>
            <span className="d-block text-muted">+12.5%</span>
          </div>
          <div className="col-md-6">
          <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Chart5;
