import { useContext, useEffect, useState } from "react";
//import WalletService from "../../../app/services/wallet";
import { WalletContext } from "../../../app/contexts/wallet-context";
// import WaxcloudService from "../../../app/services/wallet/waxcloud";
// import AnchorLinkService from "../../../app/services/wallet/anchor-link";
//import { motion } from 'framer-motion';
import PageTransition from "../../../app/animations/page-transition";
import config from "../../../app/config";
import { tokenFormat } from "../../../app/utilities/format";
import WalletTransactionAlert from "../../../app/components/wallet-transaction-alert";
import Helmet from "react-helmet";

const Transfer = () => {
  const wallet = useContext(WalletContext);
  const { user, walletAccount } = wallet;
  const { balances } = wallet.walletAccount;
  const [formData, setFormData] = useState({
    from: "",
    to: "",
    quantity: 0,
    memo: "",
    custom_enabled: false,
    symbol: config.token_symbol,
    contract: "eosio.token",
    decimals: config.token_precision,
  });

  useEffect(() => {
    setFormData((formData) => ({ ...formData, from: user ? user.actor : "" }));
  }, [user]);

  // useEffect(() => {
  //   const balance = balances.find(
  //     (balance) =>
  //       balance.contract === formData.contract &&
  //       balance.currency === formData.symbol
  //   );

  //   //setFormData((formData) => ({ ...formData, from: user ? user.actor : "" }));
  // }, [balances]);

  const handleFullBalance = () => {
    const balance = balances.find(
      (balance) =>
        balance.contract === formData.contract &&
        balance.currency === formData.symbol
    );

    setFormData((formData) => ({ ...formData, quantity: balance.amount }));
  };

  const handleClick = async () => {
    var walletTransaction = {};
    walletTransaction.type = "transfer";
    walletTransaction.account = {
      account: null,
      permission: "active",
    };
    walletTransaction.actions = [];

    walletTransaction.actions.push({
      account: formData.contract,
      name: "transfer",
      data: {
        from: formData.from,
        to: formData.to,
        quantity: `${tokenFormat(formData.quantity, formData.decimals)} ${
          formData.symbol
        }`,
        memo: formData.memo,
      },
    });

    wallet.submitTransaction(walletTransaction);

    //////Move this code to common Wallet Transaction handler/////
    // WalletService.initProvider(provider);
    // switch (provider) {
    //   case "waxcloud":
    //     var waxcloudConnected = await WalletService.serviceProvider.connect();
    //     if (waxcloudConnected !== true) {
    //       console.log("Not connected");
    //       return;
    //     }

    //     var waxcloudUserAccount = await WalletService.serviceProvider.login();
    //     if(!waxcloudUserAccount){
    //         console.log('Not logged in');
    //         return;
    //     }

    //     walletTransaction.account = {
    //       account: waxcloudUserAccount,
    //       permission: "active",
    //     };

    //     WalletService.serviceProvider
    //       .transaction(walletTransaction)
    //       .then(function (tx) {
    //         console.log(tx);
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });

    //     // WalletService.serviceProvider
    //     //   .connect()
    //     //   .then(function (connected) {
    //     //     WalletService.serviceProvider
    //     //       .login()
    //     //       .then(function (userAccount) {
    //     //         walletTransaction.account = {
    //     //           account: userAccount,
    //     //           permission: "active",
    //     //         };
    //     //         WalletService.serviceProvider
    //     //           .transaction(walletTransaction)
    //     //           .then(function (tx) {
    //     //             console.log(tx);
    //     //           })
    //     //           .catch(function (error) {
    //     //             console.log(error);
    //     //           });
    //     //       })
    //     //       .catch(function (error) {
    //     //         console.log(error);
    //     //       });
    //     //   })
    //     //   .catch(function (error) {
    //     //     console.log(error);
    //     //   });
    //     break;
    //   case "anchor-link":
    //     var anchorLinkConnected = await WalletService.serviceProvider.connect();
    //     if (anchorLinkConnected !== true) {
    //       console.log("Not connected");
    //       return;
    //     }

    //     var anchorLinkSession =
    //       await WalletService.serviceProvider.restoreSession();
    //     if (!anchorLinkSession) {
    //       var identity = await WalletService.serviceProvider.login();
    //       anchorLinkSession = identity.session;
    //     }

    //     if (!anchorLinkSession) {
    //       console.log("Not loggedin");
    //       return;
    //     }

    //     walletTransaction.account = {
    //       account: String(anchorLinkSession.auth.actor),
    //       permission: String(anchorLinkSession.auth.permission),
    //     };

    //     WalletService.serviceProvider
    //       .transaction(walletTransaction)
    //       .then(function (tx) {
    //         console.log(tx);
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //       });

    //     // WalletService.serviceProvider
    //     //   .connect()
    //     //   .then(async function (connected) {
    //     //     if (!WalletService.serviceProvider.session) {
    //     //       await WalletService.serviceProvider
    //     //         .login()
    //     //         .then(function (identity) {
    //     //           console.log(identity);
    //     //           const { session } = identity;
    //     //           walletTransaction.account = {
    //     //             account: String(session.auth.actor),
    //     //             permission: String(session.auth.permission),
    //     //           };
    //     //         })
    //     //         .catch(function (error) {
    //     //           console.log(error);
    //     //         });
    //     //     }

    //     //     walletTransaction.account = {
    //     //         account: 'asdfasdf',
    //     //         permission: 'active',
    //     //       };
    //     //     WalletService.serviceProvider
    //     //       .transaction(walletTransaction)
    //     //       .then(function (tx) {
    //     //         console.log(tx);
    //     //       })
    //     //       .catch(function (error) {
    //     //         console.log(error);
    //     //       });
    //     //   })
    //     //   .catch(function (error) {
    //     //     console.log(error);
    //     //   });
    //     break;
    //   default:
    //     break;
    // }

    //////Move this code to common Wallet Transaction handler/////
  };

  return (
    <>
      {/* <motion.div
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      exit={{ scaleY: 0 }}
      transition={{ duration: 0.5 }}
    > */}
    <Helmet>
        <title>Transfer Tokens | Wallet - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="container border p-2">
          <h4>Transfer Tokens</h4>
          <form>
            <div className="mb-3">
              <label className="form-label">From</label>
              <input
                type="text"
                className="form-control"
                value={formData.from}
                onChange={(event) =>
                  setFormData({ ...formData, from: event.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label className="form-label">To</label>
              <input
                type="text"
                className="form-control"
                value={formData.to}
                onChange={(event) =>
                  setFormData({ ...formData, to: event.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Quantity</label>
              <div className="row">
                <div className="col-md-8">
                  <input
                    type="number"
                    className="form-control"
                    value={formData.quantity}
                    onChange={(event) =>
                      setFormData({ ...formData, quantity: event.target.value })
                    }
                  />
                </div>
                <div className="col-md-4">
                  <select
                    className="form-select"
                    onChange={(event) => {
                      const contract = event.target.value.split(":")[0];
                      const currency = event.target.value.split(":")[1];

                      const balance = balances.find(
                        (balance) =>
                          balance.contract === contract &&
                          balance.currency === currency
                      );

                      setFormData((formData) => ({
                        ...formData,
                        contract: balance.contract,
                        symbol: balance.currency,
                        decimals: balance.decimals,
                      }));
                    }}
                    value={`${formData.contract}:${formData.symbol}`}
                  >
                    {balances &&
                      balances.map((balance, index) => (
                        <option
                          value={`${balance.contract}:${balance.currency}`}
                          key={index}
                        >
                          {balance.amount} {balance.currency}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <a
                className="text-left"
                style={{ cursor: "pointer" }}
                onClick={() => handleFullBalance()}
              >
                Send entire balance
              </a>
              <a
                className="text-right"
                style={{ cursor: "pointer", float: "right" }}
                onClick={() => {
                  setFormData({
                    ...formData,
                    custom_enabled: !formData.custom_enabled,
                  });
                }}
              >
                Use custom token
              </a>
            </div>
            {formData.custom_enabled ? (
              <>
                <div className="mb-3">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="form-label">Symbol</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.symbol}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            symbol: event.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Contract</label>
                      <input
                        type="text"
                        className="form-control"
                        value={formData.contract}
                        onChange={(event) =>
                          setFormData({
                            ...formData,
                            contract: event.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}

            <div className="mb-3">
              <label className="form-label">Memo</label>
              <input
                type="text"
                className="form-control"
                value={formData.memo}
                onChange={(event) =>
                  setFormData({ ...formData, memo: event.target.value })
                }
              />
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleClick()}
            >
              {formData.quantity > 0
                ? `Transfer ${formData.quantity} ${formData.symbol}`
                : `Transfer`}
            </button>
            {/* <button
            type="button"
            className="btn btn-primary"
            onClick={() => handleClick(WaxcloudService.provider)}
          >
            Submit via Wax Cloud
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => handleClick(AnchorLinkService.provider)}
          >
            Submit via Anchor Link
          </button> */}
          </form>
        </div>
        <WalletTransactionAlert />

        {/* <button type="submit" className="btn btn-primary" onClick={handleClick}>
        Test Transaction
      </button> */}
      </PageTransition>
      {/* </motion.div> */}
    </>
  );
};

export default Transfer;
