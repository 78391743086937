import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { SocketContext } from "../../../app/contexts/socket-context";

// const topDapps = [
//   {
//     name: "Alien worlds",
//     y: 50,
//   },
//   {
//     name: "Farmers worl",
//     y: 10,
//   },
//   {
//     name: "Atomic assets",
//     y: 10,
//   },
//   {
//     name: "Alcor",
//     y: 10,
//   },
//   {
//     name: "Others",
//     y: 20,
//   },
// ];

const dummyData = Array.from({ length: 10 }, (element, index) => {
  return {
    x: (moment().unix() + (index - 10)) * 1000,
    y: 0,
  };
});

const TopDappsChart = () => {
  const socket = useContext(SocketContext);
  // const [options, setOptions] = useState({
  //   chart: {
  //     type: "pie",
  //   },
  //   title: {
  //     text: "Top Dapps",
  //     verticalAlign: "middle",
  //     floating: true,
  //   },
  //   tooltip: {
  //     pointFormat: "{series.name}: <b>{point.percentage:.0f}%</b>",
  //   },
  //   plotOptions: {
  //     pie: {
  //       allowPointSelect: true,
  //       cursor: "pointer",
  //       dataLabels: {
  //         enabled: true,
  //         format: "{point.name}: {y} %",
  //       },
  //     },
  //   },
  //   credits: {
  //     enabled: false,
  //   },
  //   series: [
  //     {
  //       name: "Registrations",
  //       colorByPoint: true,
  //       innerSize: "50%",
  //       data: topDapps,
  //       // data: [
  //       //     {
  //       //       name: "Alien worlds",
  //       //       y: 50,
  //       //     },
  //       //     {
  //       //       name: "Farmers worl",
  //       //       y: 10,
  //       //     },
  //       //     {
  //       //       name: "Atomic assets",
  //       //       y: 10,
  //       //     },
  //       //     {
  //       //       name: "Alcor",
  //       //       y: 10,
  //       //     },
  //       //     {
  //       //       name: "Others",
  //       //       y: 20,
  //       //     },
  //       // ],
  //     },
  //   ],
  // });

  const chartRef = useRef(null);
  const [options, setOptions] = useState({
    chart: {
      type: "areaspline",
    },
    credits: {
      enabled: false,
    },
    rangeSelector: {
      verticalAlign: 'top',
      x: 0,
      y: 0
  },
    title: null,
    subtitle: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      labels: {
        overflow: "justify",
      },
    },
    yAxis: {
      title: null,
    },
    tooltip: {
      shared: true,
      //split: true,
      //valueSuffix: " millions",
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5,
      },
      area: {
        stacking: "normal",
        lineColor: "#666666",
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: "#666666",
        },
      },
    },
    // legend: {
    //     useHTML: true,
    //     labelFormatter: function () {
    //         return '<span title="' + this.name + '">' + this.name + '</span>';
    //     }
    // },
    // series: [
    //   {
    //     name: "Alien Worlds",
    //     color: "#143DC9",
    //     data: Array.from({ length: 10 }, (element, index) => {
    //       return {
    //         x: (moment().unix() + (index - 10)) * 1000,
    //         y: Math.floor(Math.random() * 1000000),
    //       };
    //     }),
    //   },
    //   {
    //     name: "BC Brawlers",
    //     color: "#04D489",
    //     data: Array.from({ length: 10 }, (element, index) => {
    //       return {
    //         x: (moment().unix() + (index - 10)) * 1000,
    //         y: Math.floor(Math.random() * 1000000),
    //       };
    //     }),
    //   },
    //   {
    //     name: "Crypto Eleven",
    //     color: "#57038C",
    //     data: Array.from({ length: 10 }, (element, index) => {
    //       return {
    //         x: (moment().unix() + (index - 10)) * 1000,
    //         y: Math.floor(Math.random() * 1000000),
    //       };
    //     }),
    //   },
    //   {
    //     name: "Farmers World",
    //     color: "#FE2BC5",
    //     data: Array.from({ length: 10 }, (element, index) => {
    //       return {
    //         x: (moment().unix() + (index - 10)) * 1000,
    //         y: Math.floor(Math.random() * 1000000),
    //       };
    //     }),
    //   },
    //   // {
    //   //   name: "Dragons Walley",
    //   //   data: Array.from({ length: 10 }, (element, index) =>{ return {x:(moment().unix() + index)* 1000,y:Math.floor(Math.random() * 1000000) }}),
    //   // },
    // ],
    series: [
      { name: "Top 1", color: "#143DC9", data: [] },
      { name: "Top 2", color: "#04D489", data: [] },
      { name: "Top 3", color: "#57038C", data: [] },
      { name: "Top 4", color: "#FE2BC5", data: [] },
      { name: "Top 5", color: "#7798BF", data: [] },
    ],
  });

  useEffect(() => {
    if (!socket?.connected) return;

    socket.off("topDapps").on("topDapps", (topDapps) => {
      var timestamp = moment().utc().valueOf();
     
      topDapps.forEach(function (topDapp, index) {
        if (chartRef.current.chart.series[index].data.length >= 10) {
          chartRef.current.chart.series[index].data[0].remove();
        }
        chartRef.current.chart.series[index].update({ 
          name: topDapp.name,
          data: [...chartRef.current.chart.series[index].data,{
            x: timestamp,
            y: topDapp.count,
          }] 
        });        
        // chartRef.current.chart.series[index].addPoint({
        //   x: timestamp,
        //   y: topDapp.count,
        // });
      });
      //chartRef.current.chart.redraw();
    });
  }, [socket, socket?.connected]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     //assign interval to a variable to clear it.

  //     // let seriesData = [];
  //     // topDapps.forEach(function (topDapp) {
  //     //   seriesData.push({
  //     //     name: topDapp.name,
  //     //     //y: parseFloat(((topDapp.count / total) * 100).toFixed(2)),
  //     //     y: parseFloat((Math.random() * 100).toFixed(2)),
  //     //   });
  //     // });

  //     // setOptions({
  //     //   series: [
  //     //     {
  //     //       data: seriesData,
  //     //     },
  //     //   ],
  //     // });
  //     // const timestamp = (moment().unix() + index) * 1000;
  //     chartRef.current.chart.series.forEach(function (series, index) {
  //       if (series.data.length >= 10) {
  //         series.data[0].remove();
  //       }
  //       series.addPoint({
  //         x: moment().unix() * 1000,
  //         y: Math.floor(Math.random() * 1000000),
  //       });
  //     });
  //     chartRef.current.chart.redraw();
  //   }, 1000);

  //   return () => clearInterval(intervalId); //This is important
  // }, []);

  return (
    <div className="pt-2 pb-2">
      <HighchartsReact
        highcharts={Highcharts}
       // constructorType={'stockChart'}
        options={options}
        ref={chartRef}
      />
    </div>
  );
};

export default TopDappsChart;
