import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { useState } from "react";



const Chart3 = () => {
  const [options, setOptions] = useState( {
    chart: {
        type: 'column',
        height:100
    },

    title: {
        text: 'Highcharts responsive chart'
    },

    subtitle: {
        text: 'Resize the frame or click buttons to change appearance'
    },

    legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical'
    },

    xAxis: {
        categories: ['Apples', 'Oranges', 'Bananas'],
        labels: {
            x: -10
        }
    },

    yAxis: {
        allowDecimals: false,
        title: {
            text: 'Amount'
        }
    },

    series: [{
        name: 'Christmas Eve',
        data: [1, 4, 3]
    }, {
        name: 'Christmas Day before dinner',
        data: [6, 4, 2]
    }, {
        name: 'Christmas Day after dinner',
        data: [8, 4, 3]
    }],

    responsive: {
        rules: [{
            condition: {
                maxWidth: 100
            },
            chartOptions: {
                legend: {
                    align: 'center',
                    verticalAlign: 'bottom',
                    layout: 'horizontal'
                },
                yAxis: {
                gridLineWidth: 0,
                minorGridLineWidth: 0,
                    labels: {
                         enabled:false,
                    },
                    title: {
                        text: null
                    }
                },
                 xAxis: {
                    labels: {
                         enabled:false,
                    },
                    title: {
                        text: null
                    }
                },
                title:{
    							text: '',
                },
                subtitle: {
                    text: null
                },
                tooltip:{
                enabled:false,
                },
                legend:{
                enabled:false,
                },
                credits: {
                    enabled: false
                }
            }
        }]
    }
});

  return (
    <>
      <div className="card card-body">
              <div className="row">
                <div className="col-md-6">
                  <span className="d-block fw-bold">Accounts</span>
                  <span className="d-block text-muted">12,345</span>
                  <span className="d-block text-muted">+12.5%</span>
                </div>
                <div className="col-md-6">
                <HighchartsReact highcharts={Highcharts} options={options} />
                </div>
              </div>
            </div>
    </>
  );
};

export default Chart3;
