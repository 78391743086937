import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more";
import highchartsSolidGauge from "highcharts/modules/solid-gauge";
import { useState } from "react";

highchartsMore(Highcharts);
highchartsSolidGauge(Highcharts);


const Chart2 = () => {
  const [options, setOptions] = useState({
    chart: {
      type: "solidgauge",
    },
    credits: {
      enabled: false
    },
    title: null,
    tooltip: {
      // enabled: false,
      borderWidth: 0,
      backgroundColor: "none",
      shadow: false,
      style: {
        color: "#fff",
        fontSize: "16px",
      },
      valueSuffix: "%",
      pointFormat:
        '{series.name}<br><span style=" font-weight: bold">{point.y}</span>',
      positioner: function (labelWidth) {
        return {
          x: (this.chart.chartWidth - labelWidth) / 2,
          y: this.chart.plotHeight / 2 - 20,
        };
      },
    },
    legend: {
      enabled: false,
      floating: true,
      verticalAlign: 'xbottom',
      align:'right',
      layout: 'vertical',
     // y: $(this).find('#container').height()/4, //chart.height/4
      labelFormatter : function() { 
        return this.name + this.y; 
          //var total = 0, percentage; $.each(this.series.data, function() { total+=this.y; });
         // percentage=((this.y/total)*100).toFixed(2); 
         // return this.name + this.y + '(<span style=\"color:'+this.color+'\">'+percentage+ '%)'; 
      }

},      
    pane: {
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          // Track for NET
          outerRadius: "60%",
          innerRadius: "51%",
          backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
            .setOpacity(0.3)
            .get(),
          borderWidth: 0,
        },
        {
          // Track for CPU
          outerRadius: "50%",
          innerRadius: "41%",
          backgroundColor: Highcharts.color(Highcharts.getOptions().colors[1])
            .setOpacity(0.3)
            .get(),
          borderWidth: 0,
        },
        {
          // Track for RAM
          outerRadius: "40%",
          innerRadius: "30%",
          backgroundColor: Highcharts.color(Highcharts.getOptions().colors[2])
            .setOpacity(0.3)
            .get(),
          borderWidth: 0,
        },
      ],
    },

    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: [],
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false,
        },
        linecap: "round",
        stickyTracking: false,
        rounded: true,
        showInLegend: false,
        
      },
    },

    series: [     
      {
        name: "NET",
        data: [
          {
            color: Highcharts.getOptions().colors[0],
            radius: "60%",
            innerRadius: "51%",
            y: 80,
          },
        ],
      },
      {
        name: "CPU",
        data: [
          {
            color: Highcharts.getOptions().colors[1],
            radius: "50%",
            innerRadius: "41%",
            y: 65,
          },
        ],
      },
      {
        name: "RAM",
        data: [
          {
            color: Highcharts.getOptions().colors[2],
            radius: "40%",
            innerRadius: "30%",
            y: 50,
          },
        ],
      },
    ],
  });

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
       
      </div>
    </>
  );
};

export default Chart2;
