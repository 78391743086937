import { useState } from "react";
import { Link } from "react-router-dom";
import TopHolders from "./topholders";
import TopStake from "./topstake";
import TopRam from "./topram";

const RichListTab = () => {
  //const [topHolders, setTopHolders] = useState(topHoldersData);
  const [activeTab, setActiveTab] = useState("topstake");

  return (
    <>
    <div className="container mt-5">
      <ul className="nav nav-tabs" id="richlistTab">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "topstake" ? "active" : ""}`}
            id="topstake-tab"
            data-bs-toggle="tab"
            data-bs-target="#topstake"
            type="button"
            role="tab"
            aria-controls="topstake"
            aria-selected="true"
            onClick={() => setActiveTab("topstake")}
          >
            Top Stake
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "topholders" ? "active" : ""}`}
            id="topholders-tab"
            data-bs-toggle="tab"
            data-bs-target="#topholders"
            type="button"
            role="tab"
            aria-controls="topholders"
            aria-selected="true"
            onClick={() => setActiveTab("topholders")}
          >
            Top Holders
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "topram" ? "active" : ""}`}
            id="topram-tab"
            data-bs-toggle="tab"
            data-bs-target="#topram"
            type="button"
            role="tab"
            aria-controls="topram"
            aria-selected="true"
            onClick={() => setActiveTab("topram")}
          >
            Top RAM
          </button>
        </li>
      </ul>
      <div
        className="tab-content"
        id="richlistTabContent"
        style={{ height: "auto", minHeight: "800px" }}
      >
        <div
          className={`tab-pane fade show active`}
          id="topstake"
          role="tabpanel"
          aria-labelledby="topstake-tab"
        >
          {activeTab === "topstake" ? <TopStake /> : null}
        </div>
        <div
          className={`tab-pane fade show`}
          id="topholders"
          role="tabpanel"
          aria-labelledby="topholders-tab"
        >
          {activeTab === "topholders" ? <TopHolders /> : null}
        </div>
        <div
          className={`tab-pane fade show`}
          id="topram"
          role="tabpanel"
          aria-labelledby="topram-tab"
        >
          {activeTab === "topram" ? <TopRam /> : null}
        </div>
      </div>
      </div>     
    </>
  );
};

export default RichListTab;
