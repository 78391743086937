import Skeleton from "react-loading-skeleton";

import { Link } from "react-router-dom";
import { bytesFormat, microsecondsFormat } from "../../../app/utilities/format";


const Summary = ({chainInfo, statistics}) => { 

  return (
    <div className="container">
      <div className="row p-2 border">
        <div className="col">
          <span className="d-block fw-bold">${statistics.token_price} USD</span>
          <span className="d-block text-muted">WAX Price</span>
        </div>
        <div className="col">
          <span className="d-block fw-bold">{statistics.total_accounts.toLocaleString()}</span>
          <span className="d-block text-muted">Accounts</span>
        </div>
        {/* <div className="col">
          <span className="d-block fw-bold">7262480</span>
          <span className="d-block text-muted">Transactions</span>
        </div>
        <div className="col">
          <span className="d-block fw-bold">238500 K</span>
          <span className="d-block text-muted">Daily Active Users</span>
        </div> */}
        <div className="col">
          <span className="d-block fw-bold">
            {chainInfo ? (
              <Link to={`/block/${chainInfo.head_block_num}`}>
                {chainInfo.head_block_num.toLocaleString()}
              </Link>
            ) : (
              <Skeleton />
            )}
          </span>
          <span className="d-block text-muted">Current Block</span>
        </div>
        <div className="col">
          <span className="d-block fw-bold">{bytesFormat(statistics.total_ram)}</span>
          <span className="d-block text-muted">RAM reserved</span>
        </div>
        {/* <div className="col">
          <span className="d-block fw-bold">{microsecondsFormat(statistics.cpu_limit,2)}</span>
          <span className="d-block text-muted">CPU Limit</span>
        </div> */}
      </div>
    </div>
  );
};

export default Summary;
