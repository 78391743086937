import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { useState } from "react";

const Chart1 = () => {
  const [options, setOptions] = useState({
    chart: {
      type: "areaspline",
    },
    credits: {
      enabled: false
    },
    title: null,
    subtitle: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      labels: {
        overflow: "justify",
      },
    },
    yAxis: {
      title: null,
    },
    tooltip: {
      split: true,
      valueSuffix: " millions",
    },
    plotOptions: {
      areaspline: {
        fillOpacity: 0.5
    },
      area: {
        stacking: "normal",
        lineColor: "#666666",
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: "#666666",
        },
      },
    },
    // legend: {
    //     useHTML: true,
    //     labelFormatter: function () {
    //         return '<span title="' + this.name + '">' + this.name + '</span>';
    //     }
    // },
    series: [
      {
        name: "Alien Worlds",      
        color:"#143DC9",
        data: Array.from({ length: 10 }, (element, index) =>{ return {x:(moment().unix() + index)* 1000,y:Math.floor(Math.random() * 1000000) }}),
      },
      {
        name: "BC Brawlers",
        color:"#04D489",
        data: Array.from({ length: 10 }, (element, index) =>{ return {x:(moment().unix() + index)* 1000,y:Math.floor(Math.random() * 1000000) }}),
      },
      {
        name: "Crypto Eleven",
        color: "#57038C",
        data: Array.from({ length: 10 }, (element, index) =>{ return {x:(moment().unix() + index)* 1000,y:Math.floor(Math.random() * 1000000) }}),
      },
      {
        name: "Farmers World",
        color: '#FE2BC5',
        data: Array.from({ length: 10 }, (element, index) =>{ return {x:(moment().unix() + index)* 1000,y:Math.floor(Math.random() * 1000000) }}),
      },
      // {
      //   name: "Dragons Walley",
      //   data: Array.from({ length: 10 }, (element, index) =>{ return {x:(moment().unix() + index)* 1000,y:Math.floor(Math.random() * 1000000) }}),
      // },
    ],
  });

  return (
    <>
      <h4>WAX Stats</h4>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  );
};

export default Chart1;
