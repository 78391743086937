import TransactionsTab from "./transactions-tab";
import ProxiesTab from "./proxies-tab";
import ProducersTab from "./producers-tab";
import NamebidsTab from "./namebids-tab";
import MultisigsTab from "./multisigs-tab";
import RichListTab from "./richlist-tab";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";

const Tabs = ({ chainInfo }) => {
  const location = useLocation(); 
  const history = createBrowserHistory();
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    const hash = location.hash.replace("#", "") || "producers";
    setActiveTab(hash);
  }, [location]);

  return (
    <>
      <ul className="nav nav-tabs mt-2" id="explorerTab">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "producers" ? "active" : ""}`}
            id="producers-tab"
            data-bs-toggle="tab"
            data-bs-target="#producers"
            type="button"
            role="tab"
            aria-controls="producers"
            aria-selected="true"
            onClick={() => {
              history.push({ hash: "producers" });
              setActiveTab("producers");
            }}
          >
            Guilds
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${
              activeTab === "transactions" ? "active" : ""
            }`}
            id="transactions-tab"
            data-bs-toggle="tab"
            data-bs-target="#transactions"
            type="button"
            role="tab"
            aria-controls="transactions"
            aria-selected="true"
            onClick={() => {
              history.push({ hash: "transactions" });
              setActiveTab("transactions");
            }}
          >
            Transactions
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "proxies" ? "active" : ""}`}
            id="proxies-tab"
            data-bs-toggle="tab"
            data-bs-target="#proxies"
            type="button"
            role="tab"
            aria-controls="proxies"
            aria-selected="true"
            onClick={() => {
              history.push({ hash: "proxies" });
              setActiveTab("proxies");
            }}
          >
            Proxies
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "richlist" ? "active" : ""}`}
            id="richlist-tab"
            data-bs-toggle="tab"
            data-bs-target="#richlist"
            type="button"
            role="tab"
            aria-controls="richlist"
            aria-selected="true"
            onClick={() => {
              history.push({ hash: "richlist" });
              setActiveTab("richlist");
            }}
          >
            Rich List
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "namebids" ? "active" : ""}`}
            id="namebids-tab"
            data-bs-toggle="tab"
            data-bs-target="#namebids"
            type="button"
            role="tab"
            aria-controls="namebids"
            aria-selected="true"
            onClick={() => {
              history.push({ hash: "namebids" });
              setActiveTab("namebids");
            }}
          >
            Namebids
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === "multisigs" ? "active" : ""}`}
            id="multisigs-tab"
            data-bs-toggle="tab"
            data-bs-target="#multisigs"
            type="button"
            role="tab"
            aria-controls="multisigs"
            aria-selected="true"
            onClick={() => {
              history.push({ hash: "multisigs" });
              setActiveTab("multisigs");
            }}
          >
            Multisigs
          </button>
        </li>
      </ul>
      <div
        className="tab-content"
        id="explorerTabContent"
        style={{ height: "auto", minHeight: "800px" }}
      >
        <div
          className={`tab-pane fade show ${
            activeTab === "producers" ? "active" : null
          }`}
          id="producers"
          role="tabpanel"
          aria-labelledby="producers-tab"
        >
          {activeTab === "producers" ? (
            <ProducersTab chainInfo={chainInfo} />
          ) : null}
        </div>
        <div
          className={`tab-pane fade show ${
            activeTab === "transactions" ? "active" : null
          }`}
          id="transactions"
          role="tabpanel"
          aria-labelledby="transactions-tab"
        >
          {activeTab === "transactions" ? (
            <TransactionsTab chainInfo={chainInfo} />
          ) : null}
        </div>
        <div
          className={`tab-pane fade show ${
            activeTab === "proxies" ? "active" : null
          }`}
          id="proxies"
          role="tabpanel"
          aria-labelledby="proxies-tab"
        >
          {activeTab === "proxies" ? <ProxiesTab /> : null}
        </div>
        <div
          className={`tab-pane fade show ${
            activeTab === "richlist" ? "active" : null
          }`}
          id="richlist"
          role="tabpanel"
          aria-labelledby="richlist-tab"
        >
          {activeTab === "richlist" ? <RichListTab /> : null}
        </div>
        <div
          className={`tab-pane fade show ${
            activeTab === "namebids" ? "active" : null
          }`}
          id="namebids"
          role="tabpanel"
          aria-labelledby="namebids-tab"
        >
          {activeTab === "namebids" ? <NamebidsTab /> : null}
        </div>
        <div
          className={`tab-pane fade show ${
            activeTab === "multisigs" ? "active" : null
          }`}
          id="multisigs"
          role="tabpanel"
          aria-labelledby="multisigs-tab"
        >
          {activeTab === "multisigs" ? <MultisigsTab /> : null}
        </div>
      </div>
    </>
  );
};

export default Tabs;
