import WaxcloudService from "../../app/services/wallet/waxcloud";
import AnchorLinkService from "../../app/services/wallet/anchor-link";
//import WalletService from "../../app/services/wallet";
import { useContext } from "react";
import { WalletContext } from "../../app/contexts/wallet-context";
import { Link } from "react-router-dom";

const ManageAccounts = () => {
  const wallet = useContext(WalletContext);
  const { user } = wallet;

  //   const handleWalletConnect = async (provider) => {
  //     WalletService.initProvider(provider);
  //     switch (provider) {
  //       case WaxcloudService.provider:
  //         var waxcloudConnected = await WalletService.serviceProvider.connect();
  //         if (waxcloudConnected !== true) {
  //           console.log("Not connected");
  //           return;
  //         }

  //         var waxcloudUserAccount = await WalletService.serviceProvider.login();
  //         if (!waxcloudUserAccount) {
  //           console.log("Not logged in");
  //           return;
  //         }
  //         //console.log(waxcloudUserAccount);
  //         wallet.login({
  //           actor: waxcloudUserAccount,
  //           permission: "active",
  //           provider: provider,
  //         });
  //         break;
  //       case AnchorLinkService.provider:
  //         var anchorLinkConnected = await WalletService.serviceProvider.connect();
  //         if (anchorLinkConnected !== true) {
  //           console.log("Not connected");
  //           return;
  //         }

  //         var identity = await WalletService.serviceProvider.login();
  //         var anchorLinkSession = identity.session;

  //         if (!anchorLinkSession) {
  //           console.log("Not loggedin");
  //           return;
  //         }

  //         //console.log(anchorLinkSession);
  //         wallet.login({
  //           actor: waxcloudUserAccount,
  //           permission: "active",
  //           provider: provider,
  //         });
  //         break;
  //       default:
  //         break;
  //     }
  //   };

  return (
    <>
      {user ? (
        <>
          <span className="text-white me-2"><Link to={`/account/${user.actor}`}>{user.actor}</Link></span>
          <button
            type="button"
            className="btn btn-outline-light me-2"
            onClick={wallet.logout}
          >
            Logout
          </button>
        </>
      ) : (
        <>
          <button
            type="button"
            className="btn btn-outline-light me-2"
            data-bs-toggle="modal"
            data-bs-target="#walletConnectModal"
          >
            Login
          </button>
        </>
      )}

      <div className="modal fade" id="walletConnectModal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Connect to Wallet</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="d-grid gap-2 d-md-flex ">
                <button
                  className="btn btn-outline-secondary me-md-2"
                  type="button"
                  onClick={() => wallet.connectWallet(WaxcloudService.provider)}
                >
                  WAX Cloud Wallet
                </button>
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() =>
                    wallet.connectWallet(AnchorLinkService.provider)
                  }
                >
                  Anchor
                </button>
              </div>
            </div>
            <div className="modal-footer">
              <p>Dont have wallet?</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageAccounts;
