import { Link, useNavigate, useParams } from "react-router-dom";
import BlockchainApiManager from "../../app/services/blockchainApiManager";
import { Helmet } from "react-helmet";
import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import { JSONTree } from "react-json-tree";
import ReactJson from "react-json-view";
import { WalletContext } from "../../app/contexts/wallet-context";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import PageTransition from "../../app/animations/page-transition";
import DisplayActionData from "../../app/components/blockchain/action/display-action-data";

const Transaction = () => {
  const [timeLeft, setTimeLeft] = useState(null);
  const wallet = useContext(WalletContext);
  let params = useParams();
  let navigate = useNavigate();

  const {
    isLoading,
    error,
    data: transaction,
  } = useQuery(
    [`transaction`, params.id],
    () => BlockchainApiManager.getTransaction(params.id),
    {
      onSuccess(transaction) {
        if (
          transaction &&
          transaction.trx &&
          transaction.trx.trx &&
          transaction.trx.trx.actions.length === 0
        ) {
          transaction.traces.forEach(function (trace) {
            transaction.trx.trx.actions.push(trace.act);
          });
        }
      },
    }
  );

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    if (timeLeft === 0) {
      console.log("TIME LEFT IS 0");
      setTimeLeft(null);
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  useEffect(() => {
    if (!transaction) return;

    setTimeLeft(
      180 - moment().diff(moment(transaction.block_time + "Z"), "seconds")
    );
  }, [transaction]);

  const copyAsMsig = () => {
    var walletTransaction = {};
    walletTransaction.type = "msig";
    walletTransaction.actions = [];

    if (
      transaction &&
      transaction.trx &&
      transaction.trx.trx &&
      transaction.trx.trx.actions
    ) {
      transaction.trx.trx.actions.forEach(function (action) {
        walletTransaction.actions.push(action);
      });
    }

    wallet.multisig.mode = true;
    wallet.multisig.walletTransaction = Object.assign({}, walletTransaction);

    navigate("/wallet/msig");
  };

  if (!isLoading && (error || !transaction || !transaction.id)) {
    return (
      <>
        <div className="card card-body p-3">
          <h5 className="card-title">Transaction Not Found</h5>
          <p>Cannot find Transaction {params.id}</p>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{params.id} | Transaction - WAX Explorer</title>
      </Helmet>
      <PageTransition>
        <div className="card card-body p-3">
          <h5 className="card-title">
            Transaction #
            {(transaction && transaction.id) || (
              <Skeleton style={{ width: "80%" }} />
            )}
          </h5>

          <div className="row p-3">
            <div className="col-md-6 col-sm-12">
              <h6>Summary</h6>
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td className="w-50">Block Number:</td>
                    <td>
                      {transaction ? (
                        <Link to={`/block/${transaction.block_num}`}>
                          {parseInt(transaction.block_num).toLocaleString()}
                        </Link>
                      ) : (
                        <Skeleton />
                      )}
                      {/* {(transaction && transaction.block_num.toLocalString()) || <Skeleton />} */}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50">Block Time:</td>
                    <td>
                      {(transaction &&
                        moment(transaction.block_time).format("MMM DD YYYY, HH:mm:ss")) || (
                        <Skeleton />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50">Status:</td>
                    <td>
                      {transaction ? (
                        <>
                          {transaction.trx.receipt.status === "executed" ? (
                            <span className="badge bg-primary">Executed</span>
                          ) : null}
                          {/* {transaction.last_irreversible_block ? (
                            <span className="badge bg-success">Irreversible</span>
                          ) : null} */}
                          {moment().diff(
                            moment(transaction.block_time + "Z"),
                            "seconds"
                          ) <= 180 ? (
                            <span className="badge bg-warning">
                              Pending Irreversibility ({timeLeft}s)
                            </span>
                          ) : (
                            <span className="badge bg-success">
                              Irreversible
                            </span>
                          )}
                        </>
                      ) : (
                        <Skeleton style={{ width: "200px" }} />
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6 col-sm-12">
              <h6>Additional</h6>
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td className="w-50">CPU Usage:</td>
                    <td>
                      {transaction ? (
                        <>{transaction.trx.receipt.cpu_usage_us} µs</>
                      ) : (
                        <Skeleton style={{ width: "200px" }} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50">NET Usage:</td>
                    <td>
                      {transaction ? (
                        <>{transaction.trx.receipt.net_usage_words} Bytes</>
                      ) : (
                        <Skeleton style={{ width: "200px" }} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50"># of Actions/Traces : </td>
                    <td>
                      {transaction ? (
                        <>
                          {transaction.trx.trx.actions.length}/
                          {transaction.traces.length}
                        </>
                      ) : (
                        <Skeleton style={{ width: "200px" }} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-50">Actions : </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-sm"
                        onClick={copyAsMsig}
                      >
                        Copy As MSIG
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="card card-body p-3 mt-3">
          <h5 className="card-title">Actions</h5>
          <ul className="nav nav-tabs mt-2" id="transactionTab">
            <li className="nav-item">
              <button
                className="nav-link active"
                id="actions-tab"
                data-bs-toggle="tab"
                data-bs-target="#actions"
                type="button"
                role="tab"
                aria-controls="actions"
                aria-selected="true"
              >
                Actions
              </button>
            </li>
            {/* <li className="nav-item">
                <button
                  className="nav-link"
                  id="traces-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#traces"
                  type="button"
                  role="tab"
                  aria-controls="traces"
                  aria-selected="true"
                >
                  Traces
                </button>
              </li> */}
            <li className="nav-item">
              <button
                className="nav-link"
                id="raw-tab"
                data-bs-toggle="tab"
                data-bs-target="#raw"
                type="button"
                role="tab"
                aria-controls="raw"
                aria-selected="true"
              >
                Raw
              </button>
            </li>
          </ul>
          <div className="tab-content" id="transactionTabContent">
            <div
              className="tab-pane fade show active"
              id="actions"
              role="tabpanel"
              aria-labelledby="actions-tab"
            >
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Contract</th>
                      <th scope="col">Name</th>
                      <th scope="col">Authorization</th>
                      <th scope="col">Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!transaction &&
                      Array(5)
                        .fill(0)
                        .map((item, index) => (
                          <tr key={index}>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                        ))}
                    {transaction &&
                      transaction.trx.trx.actions.map((action, index) => (
                        <tr key={index}>
                          <td>
                            <Link to={`/account/${action.account}`}>
                              {action.account}
                            </Link>
                          </td>
                          <td>{action.name}</td>
                          <td>
                            {action.authorization.map((authorization) => (
                              <>
                                <div
                                  className="btn-group d-block pb-2"
                                  role="group"
                                  aria-label="Basic example"
                                >
                                  <Link
                                    to={`/account/${authorization.actor}`}
                                    className="btn btn-primary  btn-sm"
                                  >
                                    {authorization.actor}
                                  </Link>
                                  <button
                                    type="button"
                                    className="btn btn-primary  btn-sm"
                                  >
                                    {authorization.permission}
                                  </button> 
                                </div>
                              </>
                            ))}
                          </td>
                          <td>
                          <DisplayActionData
                            action={action}                           
                          />
                            {/* {Object.keys(action.data).map((dataKey, index) => (
                              <div key={index}>
                                <span className="fw-bold">{dataKey}:</span>
                                {typeof action.data[dataKey] === "object" &&
                                Array.isArray(action.data[dataKey]) ? (
                                  <>
                                    {action.data[dataKey].map(
                                      (dataValue, valueIndex) => (
                                        <div key={valueIndex}>
                                          <span>
                                            {JSON.stringify(dataValue)}
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </>
                                ) : null}

                                {typeof action.data[dataKey] === "object" &&
                                !Array.isArray(action.data[dataKey]) ? (
                                  <span>
                                    {JSON.stringify(action.data[dataKey])}
                                  </span>
                                ) : null}

                                {typeof action.data[dataKey] !== "object" ? (
                                  <span>{action.data[dataKey]}</span>
                                ) : null}
                               
                              </div>
                            ))} */}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className="tab-pane"
              id="raw"
              role="tabpanel"
              aria-labelledby="raw-tab"
            >
              <div className="table-responsive">
                {/* <JSONTree data={transaction} /> */}
                <ReactJson src={transaction} />
              </div>
            </div>
          </div>
        </div>
      </PageTransition>
    </>
  );

  // return (
  //   <>
  //     {/* {isLoading ? <Skeleton /> : null}  */}
  //     {/* {error ? (
  //       <>
  //         <div className="card card-body p-3">
  //           <h5 className="card-title">Transaction Not Found</h5>
  //           <p>Cannot find Transaction {params.id}</p>
  //         </div>
  //       </>
  //     ) : null} */}

  //     {transaction ? (
  //       <>
  //         <div className="card card-body p-3">
  //           <h5 className="card-title">Transaction #{transaction.id}</h5>
  //           <div className="row p-3">
  //             <div className="col-6">
  //               <h6>Summary</h6>
  //               <table>
  //                 <tr>
  //                   <td>Block Number:</td>
  //                   <td>{transaction.block_num}</td>
  //                 </tr>
  //                 <tr>
  //                   <td>Block Time:</td>
  //                   <td>{transaction.block_time}</td>
  //                 </tr>
  //                 <tr>
  //                   <td>Status:</td>
  //                   <td>
  //                     <div className="bd-example">
  //                       {transaction.trx.receipt.status === "executed" ? (
  //                         <span className="badge bg-primary">Executed</span>
  //                       ) : null}
  //                       {transaction.last_irreversible_block ? (
  //                         <span className="badge bg-success">Irreversible</span>
  //                       ) : null}
  //                     </div>
  //                   </td>
  //                 </tr>
  //               </table>
  //             </div>
  //             <div className="col-6">
  //               <h6>Additional</h6>
  //               <table>
  //                 <tr>
  //                   <td>CPU Usage:</td>
  //                   <td>{transaction.trx.receipt.cpu_usage_us} µs</td>
  //                 </tr>
  //                 <tr>
  //                   <td>NET Usage:</td>
  //                   <td>{transaction.trx.receipt.net_usage_words * 8} Bytes</td>
  //                 </tr>
  //                 <tr>
  //                   <td># of Actions/Traces : </td>
  //                   <td>
  //                     {transaction.trx.trx.actions.length}/
  //                     {transaction.traces.length}
  //                   </td>
  //                 </tr>
  //               </table>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="card card-body p-3 mt-3">
  //           <h5 className="card-title">Actions</h5>
  //           <ul className="nav nav-tabs mt-2" id="transactionTab">
  //             <li className="nav-item">
  //               <button
  //                 className="nav-link active"
  //                 id="actions-tab"
  //                 data-bs-toggle="tab"
  //                 data-bs-target="#actions"
  //                 type="button"
  //                 role="tab"
  //                 aria-controls="actions"
  //                 aria-selected="true"
  //               >
  //                 Actions
  //               </button>
  //             </li>
  //             {/* <li className="nav-item">
  //               <button
  //                 className="nav-link"
  //                 id="traces-tab"
  //                 data-bs-toggle="tab"
  //                 data-bs-target="#traces"
  //                 type="button"
  //                 role="tab"
  //                 aria-controls="traces"
  //                 aria-selected="true"
  //               >
  //                 Traces
  //               </button>
  //             </li> */}
  //             <li className="nav-item">
  //               <button
  //                 className="nav-link"
  //                 id="raw-tab"
  //                 data-bs-toggle="tab"
  //                 data-bs-target="#raw"
  //                 type="button"
  //                 role="tab"
  //                 aria-controls="raw"
  //                 aria-selected="true"
  //               >
  //                 Raw
  //               </button>
  //             </li>
  //           </ul>
  //           <div className="tab-content" id="transactionTabContent">
  //             <div
  //               className="tab-pane fade show active"
  //               id="actions"
  //               role="tabpanel"
  //               aria-labelledby="actions-tab"
  //             >
  //               <table className="table">
  //                 <thead>
  //                   <tr>
  //                     <th scope="col">Contract</th>
  //                     <th scope="col">Name</th>
  //                     <th scope="col">Authorization</th>
  //                     <th scope="col">Data</th>
  //                   </tr>
  //                 </thead>
  //                 <tbody>
  //                   {transaction.trx.trx.actions.map((action, index) => (
  //                     <tr key={index}>
  //                       <td>{action.account}</td>
  //                       <td>{action.name}</td>
  //                       <td>{JSON.stringify(action.authorization)}</td>
  //                       <td>{JSON.stringify(action.data)}</td>
  //                     </tr>
  //                   ))}
  //                 </tbody>
  //               </table>
  //             </div>
  //             <div
  //               className="tab-pane"
  //               id="raw"
  //               role="tabpanel"
  //               aria-labelledby="raw-tab"
  //             >
  //               <JSONTree data={transaction} />
  //             </div>
  //           </div>
  //         </div>
  //       </>
  //     ) : null}
  //   </>
  // );
};

export default Transaction;
