import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

const Chart1 = () => {
  const [options, setOptions] = useState({
    series: [
        {
            name: "Alien Worlds",
            data: Array.from({ length: 10 }, (element, index) =>{ return {x:(moment().unix() + index)* 1000,y:Math.floor(Math.random() * 1000000) }}),
          },
          {
            name: "BC Brawlers",
            data: Array.from({ length: 10 }, (element, index) =>{ return {x:(moment().unix() + index)* 1000,y:Math.floor(Math.random() * 1000000) }}),
          },
          {
            name: "Crypto Eleven",
            data: Array.from({ length: 10 }, (element, index) =>{ return {x:(moment().unix() + index)* 1000,y:Math.floor(Math.random() * 1000000) }}),
          },
          {
            name: "Farmers World",
            data: Array.from({ length: 10 }, (element, index) =>{ return {x:(moment().unix() + index)* 1000,y:Math.floor(Math.random() * 1000000) }}),
          },
          {
            name: "Dragons Walley",
            data: Array.from({ length: 10 }, (element, index) =>{ return {x:(moment().unix() + index)* 1000,y:Math.floor(Math.random() * 1000000) }}),
          },
    ],
    options: {
      theme:{
        mode:"dark"
      },
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        // categories: [
        //   "2018-09-19T00:00:00.000Z",
        //   "2018-09-19T01:30:00.000Z",
        //   "2018-09-19T02:30:00.000Z",
        //   "2018-09-19T03:30:00.000Z",
        //   "2018-09-19T04:30:00.000Z",
        //   "2018-09-19T05:30:00.000Z",
        //   "2018-09-19T06:30:00.000Z",
        // ],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  });
  return (
    <>
      <ReactApexChart
        options={options.options}
        series={options.series}
        type="area"
        height={350}
      />
    </>
  );
};

export default Chart1;
