import * as React from "react";
import { Outlet } from "react-router-dom";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../app/components/error-fallback";

import TopNavBar from "./components/top-navbar";
import Footer from "./components/footer";

function DefaultLayout() {
  return (
    <>
      <TopNavBar />
      <div className="container mt-5">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Outlet />
        </ErrorBoundary>
      </div>
      <Footer />
      {/* <ErrorBoundary
          fallbackRender={({ error }) => (
            <div>
              There was an error!
              <pre style={{ whiteSpace: "normal" }}>{error.message}</pre>
            </div>
          )}
        >
          <React.Suspense
            fallback={<p>Waiting for suspense Outlet component...</p>}
          > */}

      {/* </React.Suspense>
        </ErrorBoundary> */}
    </>
    // <div>
    //   <h1>App</h1>
    //   <React.Suspense
    //     fallback={<p>Waiting for suspense Outlet component...</p>}
    //   >
    //     <Outlet />
    //   </React.Suspense>
    // </div>
  );
}

export default DefaultLayout;
