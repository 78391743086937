import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import config from "../../../../app/config";
//import producersData from "../../../../dummy-data/producers.json";
import ApiManager from "../../../../app/services/apiManager";
import Skeleton from "react-loading-skeleton";

const ProducersTab = React.memo(({ chainInfo }) => {
  // const [producers, setProducers] = useState(producersData);
 
  const {
    //  isLoading,
    // error,
    data: producers,
  } = useQuery([`producers`], () => ApiManager.getProducers(), {});

  useEffect(() => {
    console.log("producers render");
  }, []);

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Rank</th>
            <th scope="col">Guilds</th>
            <th scope="col">Status</th>
            <th scope="col">Total Votes (WAX)</th>
            <th scope="col">Location</th>
          </tr>
        </thead>
        <tbody>
          {!producers &&
            Array(5)
              .fill(0)
              .map((item, index) => (
                <tr key={index}>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              ))}
          {producers &&
            producers.map((producer, index) => (
              <tr key={index}>
                <td>{producer.rank}</td>
                <td>
                  <img
                    src={`${
                      producer.logo
                        ? `${config.imagekit.default_url_endpoint}/${producer.logo}`
                        : "/no-image.png"
                    }`}
                    alt=""
                    width={30}
                    height={30}
                  />
                  <Link to={`/account/${producer.owner}`}>
                    {producer.owner}
                  </Link>
                </td>
                <td>
                  {chainInfo && producer.owner ===  chainInfo.head_block_producer ? (
                    <span className="badge bg-success">Producing</span>
                  ) : (
                    <>
                      {producer.rank <= 21 ? (
                        <span className="badge bg-primary">Top 21</span>
                      ) : (
                        <span className="badge bg-info">Standby</span>
                      )}
                    </>
                  )}
                </td>
                <td>{producer.actual_votes.toLocaleString()}</td>
                <td>{producer.location_name}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
});

export default ProducersTab;
