import Summary from "./components/summary";
import Tabs from "./components/tabs";
import TopDappsChart from "./components/topdapps-chart";
import { motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import PageTransition from "../../app/animations/page-transition";
import BlockchainApiManager from "../../app/services/blockchainApiManager";
import { useQuery } from "react-query";
import {
  SocketContext,
  SocketContextProvider,
} from "../../app/contexts/socket-context";
import Helmet from "react-helmet";
import TopDappsChart2 from "./components/topdapps-chart2";

function Home() {
  const socket = useContext(SocketContext);

  const [chainInfo, setChainInfo] = useState(null);
  const [statistics, setStatistics] = useState({
    token_price: 0,
    total_accounts: 0,
    total_transactions: 0,
    total_ram: 0,
    cpu_limit: 0,
  });

  useEffect(() => {
    console.log("home render");
  }, []);

  // const { data: chainInfo } = useQuery(
  //   [`chainInfo`],
  //   () => BlockchainApiManager.getInfo(),
  //   { refetchInterval: 5000 }
  // );

  useEffect(() => {
    if (!socket) return;

    socket.off("chainInfo").on("chainInfo", (chainInfo) => {     
      setChainInfo(chainInfo);
    });

    socket.off("statistics").on("statistics", (statistics) => {
      setStatistics(statistics);
    });
  }, [socket, socket?.connected]);

  return (
    <>
      <Helmet>
        <title>WAX Block Explorer and Wallet</title>
      </Helmet>
      <PageTransition>
        <h3>WAX Stats</h3>
        {/* <TopDappsChart /> */}
        <TopDappsChart2 />
        <Summary chainInfo={chainInfo} statistics={statistics} />
        <Tabs chainInfo={chainInfo} />
      </PageTransition>
    </>
  );
}

const HomeWrapper = () => {
  return (
    <SocketContextProvider>
      <Home />
    </SocketContextProvider>
  );
};

export default HomeWrapper;
